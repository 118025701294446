/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

const StagingIdentifier = () => {
  const env = process.env.NODE_ENV ?? process.env.REACT_APP_DEV_ENV;
  if (!env) return null;

  if (env?.startsWith("dev") || env === "staging") {
    return (
      <div
        css={[
          tw`inline-block px-3 py-px text-xs tracking-wide uppercase rounded-full text-sky-800 bg-sky-100`,
          env === "staging" && tw`text-teal-800 bg-teal-500/20`,
        ]}
      >
        {env}
      </div>
    );
  }
  return null;
};

export default StagingIdentifier;
