import { buildQueryArray, separateByComma } from "../utility/utilityFunctions";

/* Deprecated */

const statusMap = {
  "order-set-variants": "order-set-status",
  "history-items": "order-status",
  "order-variants-report": "order-status",
};

const dateMap = {
  "order-set-history": "submitted-at-range",
  "order-set-variants": "order-set-submitted-at-range",
  "history-items": "order-submitted-at-range",
  "order-variants-report": "order-submitted-at-range",
};

const orderSetHistoryTypes = [
  "order-set-history",
  "order-variants-report",
  "order-set-variants",
];

const handleHistoryItemStatus = (filterObject) => {
  if (filterObject.status === "order-canceled") {
    return "filter[is-order-canceled]=true";
  } else if (filterObject.status === "compliance-canceled") {
    return "filter[is-compliance-canceled]=true";
  } else if (filterObject.status === "destroyed") {
    return "filter[is-destroyed]=true";
  } else if (filterObject.status === "not-ordered") {
    return "filter[not-ordered]=true";
  } else {
    return `filter[order-status]=${filterObject.status}`;
  }
};

const handleItemOrderType = (filterObject) => {
  if (filterObject.itemOrderType === "inventory") {
    return "filter[is-inventory]=true";
  } else if (filterObject.itemOrderType === "on-demand") {
    return "filter[is-on-demand]=true";
  } else {
    let dateArray = filterObject.itemOrderType.split("-");
    return `filter[order-month][month]=${
      dateArray[0]
    }&filter[order-month][year]=${
      dateArray[1]
    }&filter[order-month][is-ad-hoc]=${
      dateArray[2] === "Ad Hoc" ? true : false
    }`;
  }
};

export const buildFilters = (
  filterObject,
  uniqueFilter,
  sortString,
  urlBase,
  type
) => {
  let channelString = filterObject.channel
    ? `filter[channel]=${filterObject.channel}`
    : "";
  let warehouseString =
    filterObject.warehouse && filterObject.warehouse !== "all"
      ? `filter[warehouse]=${filterObject.warehouse}`
      : "";
  let statusString =
    filterObject.status && filterObject.status.length > 0
      ? filterObject.status === "all"
        ? ""
        : filterObject.status === "sent-to-flow"
        ? "filter[po-sent-to-flow]=true"
        : filterObject.status === "not-invoiced"
        ? "filter[not-invoiced]=true"
        : type === "reports"
        ? handleHistoryItemStatus(filterObject)
        : filterObject.status.includes("bid-")
        ? `${
            filterObject.status === "bid-awarded"
              ? `filter[bid-status]=${
                  filterObject.status.split("-")[1]
                }&filter[status]=awarded`
              : `filter[bid-status]=${filterObject.status.split("-")[1]}`
          }`
        : `filter[${statusMap[type] || "status"}]=${
            filterObject.status === "all-orders"
              ? "in-progress,submitted"
              : filterObject.status
          }`
      : "";

  let orderNumString =
    filterObject.orderNum && filterObject.orderNum.length > 0
      ? `filter[order-number]=${filterObject.orderNum}`
      : "";

  let itemOrderTypeString =
    filterObject.itemOrderType && filterObject.itemOrderType !== "all"
      ? handleItemOrderType(filterObject)
      : "";
  let orderTypeString =
    filterObject.orderType && filterObject.orderType !== "all"
      ? `filter[order-type]=${filterObject.orderType}`
      : "";

  let dateString =
    filterObject.fromDate &&
    filterObject.toDate &&
    filterObject.status !== "draft"
      ? (filterObject.status === "submitted" &&
          orderSetHistoryTypes.includes(type)) ||
        !orderSetHistoryTypes.includes(type)
        ? `filter[${dateMap[type] ?? "submitted-at-range"}]=${
            filterObject.fromDate
          } - ${filterObject.toDate}`
        : ""
      : "";

  let poSubmittedDateRangeString =
    filterObject.poSubmittedFromDate && filterObject.poSubmittedToDate
      ? `filter[submitted-at-range]=${filterObject.poSubmittedFromDate} - ${filterObject.poSubmittedToDate}`
      : "";
  let poInMarketDateRangeString =
    filterObject.poInMarketFromDate && filterObject.poInMarketToDate
      ? `filter[in-market-date-range]=${filterObject.poInMarketFromDate} - ${filterObject.poInMarketToDate}`
      : "";
  let poStatusString =
    filterObject.poStatus && filterObject.poStatus !== "all"
      ? `filter[status]=${filterObject.poStatus}`
      : "";

  let seqString =
    filterObject.itemNumber && filterObject.itemNumber.length > 0
      ? `filter[item-number]=${filterObject.itemNumber}`
      : "";
  let itemNumbersString =
    filterObject.itemNumbers && filterObject.itemNumbers.length > 0
      ? buildQueryArray("item-numbers", filterObject.itemNumbers)
      : "";
  let addressString =
    filterObject.address && filterObject.address.length > 0
      ? `filter[address-ids]=${separateByComma(filterObject.address, "id")}`
      : "";
  let userString =
    filterObject.user && filterObject.user.length > 0
      ? `filter[user-ids]=${separateByComma(filterObject.user, "id")}`
      : "";
  let purchaserString =
    filterObject.purchaser && filterObject.purchaser.length > 0
      ? `filter[purchaser-ids]=${separateByComma(filterObject.purchaser, "id")}`
      : "";
  let progString =
    filterObject.program && filterObject.program.length > 0
      ? `filter[program-ids]=${separateByComma(filterObject.program, "id")}`
      : "";
  let brandString =
    filterObject.brand && filterObject.brand.length > 0
      ? `filter[brand-ids]=${separateByComma(filterObject.brand, "id")}`
      : "";
  let itemTypeString =
    filterObject.itemType && filterObject.itemType.length > 0
      ? `filter[item-type-ids]=${separateByComma(filterObject.itemType, "id")}`
      : "";
  let buString =
    filterObject.bu && filterObject.bu.length > 0
      ? `filter[business-unit-names]=${separateByComma(
          filterObject.bu,
          "name"
        )}`
      : "";

  let supplierString =
    filterObject.supplier && filterObject.supplier.length > 0
      ? `filter[supplier-ids]=${separateByComma(filterObject.supplier, "id")}`
      : "";
  let ruleTypeString =
    filterObject.ruleType && filterObject.ruleType.length > 0
      ? filterObject.ruleType === "all"
        ? ""
        : `filter[type]=${filterObject.ruleType}`
      : "";
  let isAccoladeString = filterObject.isAccolade
    ? filterObject.isAccolade === "yes"
      ? "filter[is-accolade]=true"
      : "filter[is-accolade]=false"
    : "";
  let isOrderableString = filterObject.isItemOrderable
    ? `filter[is-orderable]=true`
    : "";
  if (isOrderableString && filterObject.orderType === "on-demand") {
    isOrderableString += "&filter[is-orderable-for-on-demand]=true";
    isOrderableString += "&filter[is-on-national-coupon-programs]=false";
  }
  let isArchivedString =
    filterObject.isItemArchived !== null &&
    filterObject.isItemArchived !== undefined
      ? `filter[is-archived]=${filterObject.isItemArchived}`
      : "";
  let stateString =
    filterObject.stateIds && filterObject.stateIds.length > 0
      ? `filter[state-ids]=${separateByComma(filterObject.stateIds, "id")}`
      : "";
  let territoryString =
    filterObject.territory && filterObject.territory.length > 0
      ? `filter[territory-ids]=${separateByComma(filterObject.territory, "id")}`
      : "";
  let exclusiveTerritoryString =
    filterObject.exclusiveTerritory &&
    filterObject.exclusiveTerritory.length > 0
      ? `filter[exclusive-territory-ids]=${separateByComma(
          filterObject.exclusiveTerritory,
          "id"
        )}`
      : "";
  let currentTerritoryString = filterObject.currentTerritoryId
    ? `filter[territory-ids]=${filterObject.currentTerritoryId}`
    : "";
  let orderVariantIdString =
    filterObject.orderVariantIds && filterObject.orderVariantIds.length > 0
      ? `filter[ids]=${filterObject.orderVariantIds}`
      : "";
  let isPreOrderString = filterObject.isPreOrder
    ? "filter[is-pre-order]=true"
    : "";
  let isCanceledString =
    filterObject?.isCanceled === false ? "filter[is-canceled]=false" : "";
  let isPreOrderActiveString = filterObject.isPreOrderActive
    ? "filter[is-pre-order-active]=true"
    : "";
  let itemCommentAndSubTypeString =
    filterObject.itemCommentAndSubType &&
    filterObject.itemCommentAndSubType.length > 0
      ? `filter[item-comment-and-sub-type]=${filterObject.itemCommentAndSubType}`
      : "";
  let itemDescString =
    filterObject.itemDesc && filterObject.itemDesc.length > 0
      ? `filter[comment]=${filterObject.itemDesc}`
      : "";
  let focusMonthString =
    filterObject.focusMonth && filterObject.focusMonth.length > 0
      ? `filter[focus-month][month]=${filterObject.focusMonth}`
      : "";
  let focusYearString =
    filterObject.focusYear && filterObject.focusYear.length > 0
      ? `filter[focus-month][year]=${filterObject.focusYear}`
      : "";
  let orderMonthString =
    filterObject.orderMonth && filterObject.orderMonth.length > 0
      ? `filter[order-month][month]=${filterObject.orderMonth}`
      : "";
  let orderYearString =
    filterObject.orderYear && filterObject.orderYear.length > 0
      ? `filter[order-month][year]=${filterObject.orderYear}`
      : "";
  let orderWindowMonthYearString = filterObject.orderWindowMonthYear
    ? `filter[order-window-month-year]=${filterObject.orderWindowMonthYear["formattedDate"]}`
    : "";
  let orderMonthYearsString = filterObject.orderMonthYears
    ? `filter[order-month-years]=${filterObject.orderMonthYears}`
    : "";
  let inMarketYearString =
    filterObject.inMarketYear && filterObject.inMarketYear.length > 0
      ? `filter[in-market-years]=${separateByComma(
          filterObject.inMarketYear,
          "id"
        )}`
      : "";
  let inMarketYearsString =
    filterObject.inMarketYears?.length > 0
      ? `filter[in-market-years]=${separateByComma(filterObject.inMarketYears)}`
      : "";
  let programTypeString =
    filterObject.programType?.length > 0
      ? `filter[program-types]=${separateByComma(
          filterObject.programType,
          "description"
        )}`
      : "";
  const inMarketMonthYearsString =
    filterObject.inMarketMonthYears?.length > 0
      ? `filter[in-market-month-years]=${separateByComma(
          filterObject.inMarketMonthYears.map((o) => o?.id || o)
        )}`
      : "";
  const inMarketMonthsString =
    filterObject.inMarketMonths?.length > 0
      ? `filter[in-market-months]=${separateByComma(
          filterObject.inMarketMonths
        )}`
      : "";
  let isAdHocString =
    filterObject?.isAdHoc === true
      ? "filter[is-ad-hoc]=true"
      : // : filterObject?.isAdHoc === false
        // ? "filter[is-ad-hoc]=false"
        "";
  let qtyNotZeroString = filterObject.qtyNotZero
    ? "filter[qty-not-zero]=true"
    : "";
  let nonCompliantString = filterObject.nonCompliant
    ? "filter[non-compliant]=true"
    : "";
  let pageSizeString = filterObject.pageSize
    ? `page[size]=${filterObject.pageSize}`
    : "";
  let excludesSupplierString =
    filterObject.excludedSupplier && filterObject.excludedSupplier.length > 0
      ? `filter[excludes-supplier]=${filterObject.excludedSupplier}`
      : "";
  let poInvoiceNumberReferenceString =
    filterObject.poInvoiceNumber && filterObject.poInvoiceNumber.length > 0
      ? `filter[invoice-number]=${filterObject.poInvoiceNumber}`
      : "";
  let poExternalSapIdReferenceString =
    filterObject.poExternalSapId && filterObject.poExternalSapId.length > 0
      ? `filter[external-sap-id]=${filterObject.poExternalSapId}`
      : "";
  let supplierReferenceString =
    filterObject.supplierReference && filterObject.supplierReference.length > 0
      ? `filter[supplier-reference]=${filterObject.supplierReference}`
      : "";
  let c2mOrderDateString =
    filterObject.c2mOrderDateFrom && filterObject.c2mOrderDateTo
      ? `filter[order-date-range]=${filterObject.c2mOrderDateFrom} - ${filterObject.c2mOrderDateTo}`
      : "";
  let c2mInMarketDateString =
    filterObject.c2mInMarketDateFrom && filterObject.c2mInMarketDateTo
      ? `filter[in-market-date-range]=${filterObject.c2mInMarketDateFrom} - ${filterObject.c2mInMarketDateTo}`
      : "";
  let c2mTerritoryString =
    filterObject.c2mTerritory && filterObject.c2mTerritory.length > 0
      ? `filter[territory]=${filterObject.c2mTerritory}`
      : "";
  let c2mProgramString =
    filterObject.c2mProgramName && filterObject.c2mProgramName.length > 0
      ? `filter[program-name]=${filterObject.c2mProgramName}`
      : "";
  let c2mBrandsString =
    filterObject.c2mBrands && filterObject.c2mBrands.length > 0
      ? `filter[brands]=${filterObject.c2mBrands}`
      : "";
  let c2mBusinessUnitString =
    filterObject.c2mBusinessUnit && filterObject.c2mBusinessUnit.length > 0
      ? `filter[business-unit]=${filterObject.c2mBusinessUnit}`
      : "";
  let c2mDistributorString =
    filterObject.c2mDistributor && filterObject.c2mDistributor.length > 0
      ? `filter[distributor-or-abn]=${filterObject.c2mDistributor}`
      : "";
  let c2mNameString =
    filterObject.c2mName && filterObject.c2mName.length > 0
      ? `filter[name]=${filterObject.c2mName}`
      : "";
  let c2mProgramThemeString =
    filterObject.c2mProgramTheme && filterObject.c2mProgramTheme.length > 0
      ? `filter[program-theme]=${filterObject.c2mProgramTheme}`
      : "";
  let c2mItemTypeString =
    filterObject.c2mItemType && filterObject.c2mItemType.length > 0
      ? `filter[item-type]=${filterObject.c2mItemType}`
      : "";
  let programNameString =
    filterObject.programName && filterObject.programName.length > 0
      ? `filter[program-name]=${filterObject.programName}`
      : "";
  let programIdString =
    filterObject.programId && filterObject.programId.length > 0
      ? `filter[program-id-or-anaplan-code]=${filterObject.programId}`
      : "";
  let workfrontString =
    filterObject.workfrontId && filterObject.workfrontId.length > 0
      ? `filter[workfront-id]=${filterObject.workfrontId}`
      : "";
  let couponString =
    filterObject.couponBarcodeId && filterObject.couponBarcodeId.length > 0
      ? `filter[coupon-barcode-id]=${filterObject.couponBarcodeId}`
      : "";

  const supplierQuoteIdString =
    filterObject.supplierQuoteId && filterObject.supplierQuoteId.length > 0
      ? `filter[supplier-quote-number]=${filterObject.supplierQuoteId}`
      : "";

  const infoCompleteString =
    filterObject.infoComplete !== undefined
      ? `filter[info-complete]=${filterObject.infoComplete}`
      : "";

  const isCouponString =
    filterObject.isCoupon !== undefined
      ? `filter[is-coupon]=${filterObject.isCoupon}`
      : "";

  const hasItemNumberString =
    filterObject.hasItemNumber !== undefined
      ? `filter[has-item-number]=${filterObject.hasItemNumber}`
      : "";
  const isActiveString =
    filterObject.isActive !== undefined
      ? `filter[is-active]=${filterObject.isActive}`
      : "";
  const rtaDeploymentString =
    filterObject.rtaDeployment !== undefined
      ? `filter[rta-deployment]=${filterObject.rtaDeployment}`
      : "";
  const nameString = filterObject.name
    ? `filter[name]=${filterObject.name}`
    : "";

  const ocmIdsString = filterObject.ocmIds?.length
    ? `filter[order-calendar-month-ids]=${filterObject.ocmIds}`
    : "";
  const orderWindowTypeString = filterObject.orderWindowType?.length
    ? `filter[order-window-type]=${filterObject.orderWindowType}`
    : "";

  const activeProgramsString = filterObject.activePrograms
    ? `filter[active-programs]=true`
    : "";
  const isActivatedString =
    filterObject.isActivated !== undefined
      ? `filter[is-activated]=${filterObject.isActivated}`
      : "";

  const programTypeId =
    filterObject.programTypeId !== undefined
      ? `filter[program-type-id]=${filterObject.programTypeId}`
      : "";

  const codeOrName = filterObject.codeOrName
    ? `filter[code-or-name]=${filterObject.codeOrName}`
    : "";

  const orderVariantIdsString =
    filterObject.orderVariantIds?.length > 0
      ? `filter[order-variant-ids]=${filterObject.orderVariantIds.join(",")}`
      : "";

  const anaplanCodeString =
    filterObject.anaplanCode?.length > 0
      ? `filter[anaplan-code]=${filterObject.anaplanCode}`
      : "";
  const budgetIdString =
    filterObject.budgetId?.length > 0
      ? `filter[budget-id]=${filterObject.budgetId}`
      : filterObject.budgets?.length > 0
      ? `filter[budget-id]=${separateByComma(filterObject.budgets, "id")}`
      : "";
  const noBudgetsString = filterObject.noBudgets
    ? `filter[no-budgets]=true`
    : "";

  const anaplanProgramTypeString =
    filterObject.anaplanProgramType && filterObject.anaplanProgramType !== "all"
      ? `filter[anaplan-program-type]=${filterObject.anaplanProgramType}`
      : "";

  let queryArray = [
    uniqueFilter,
    channelString,
    warehouseString,
    itemOrderTypeString,
    itemNumbersString,
    orderNumString,
    orderTypeString,
    statusString,
    dateString,
    poSubmittedDateRangeString,
    poInMarketDateRangeString,
    poStatusString,
    seqString,
    addressString,
    progString,
    brandString,
    itemTypeString,
    buString,
    userString,
    purchaserString,
    supplierString,
    ruleTypeString,
    isAccoladeString,
    isOrderableString,
    isArchivedString,
    stateString,
    currentTerritoryString,
    territoryString,
    exclusiveTerritoryString,
    orderVariantIdString,
    isPreOrderActiveString,
    isPreOrderString,
    isCanceledString,
    itemCommentAndSubTypeString,
    itemDescString,
    focusMonthString,
    focusYearString,
    orderMonthString,
    inMarketYearString,
    programTypeString,
    inMarketMonthYearsString,
    inMarketYearsString,
    orderYearString,
    orderWindowMonthYearString,
    orderMonthYearsString,
    isAdHocString,
    qtyNotZeroString,
    nonCompliantString,
    c2mOrderDateString,
    c2mInMarketDateString,
    c2mTerritoryString,
    c2mProgramString,
    c2mBrandsString,
    c2mBusinessUnitString,
    c2mDistributorString,
    c2mNameString,
    c2mProgramThemeString,
    c2mItemTypeString,
    supplierQuoteIdString,
    programNameString,
    workfrontString,
    programIdString,
    couponString,
    pageSizeString,
    excludesSupplierString,
    poInvoiceNumberReferenceString,
    poExternalSapIdReferenceString,
    supplierReferenceString,
    infoCompleteString,
    isCouponString,
    hasItemNumberString,
    isActiveString,
    rtaDeploymentString,
    nameString,
    inMarketMonthsString,
    ocmIdsString,
    orderWindowTypeString,
    activeProgramsString,
    sortString,
    programTypeId,
    isActivatedString,
    codeOrName,
    orderVariantIdsString,
    anaplanCodeString,
    budgetIdString,
    noBudgetsString,
    anaplanProgramTypeString,
  ];

  let queryStringAppend = queryArray
    .filter((query) => query.length !== 0)
    .join("&");
  let filterPreCursor = queryStringAppend.length !== 0 ? "?" : "";

  let queryString = urlBase + filterPreCursor + queryStringAppend;
  return queryString;
};

/*
Errors come in multiple formats from the API currently, so this function handles each possible
error format and falls back to an unknown error if a message cannot be deliniated from the current
error.
*/
export const handleErrors = (err) => {
  if (err.response) {
    const errorPriority = [
      err.response.status === 408 && "Error: request timed out.",

      err.response.data?.error,
      err.response.data?.errors
        ?.map?.((e) => e.detail || e.title)
        ?.join?.("\n"),
      err.response.data.errors?.detail,
      err.response.errors?.detail,
      err.response.data?.message,
      err.response.data,
      "Unknown Error",
    ];
    //  return the first error that isn't falsey
    return errorPriority.reduce((a, b) => a || b, 0);
  } else {
    if (err.message?.includes("timeout")) {
      return "The request took too long. This is most likely due to a slow internet connection. Please check your connection and try again.";
    } else {
      return err.message ?? "Unknown Error";
    }
  }
};
