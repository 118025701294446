/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useInfiniteQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import client from "@services/api";

import ReportRow from "./ReportRow";
import { setReportStatus } from "./globalReportSlice";
import { RStatus, Report, ReportStatus, TReportState } from "./types";

type SingleReportProps = {
  report: TReportState;
  reportDefinition: Report;
};

const SingleReport = ({ report, reportDefinition }: SingleReportProps) => {
  const fullFileName = report.filename + "." + reportDefinition.fileExtension;
  const dispatch = useDispatch();

  const [runTimeError, setRunTimeError] = useState<null | string>(null);
  const [reportData, setReportData] = useState<null | any[]>(null);

  const {
    hasNextPage,
    fetchNextPage,
    data: queryData,
    error,
  } = useInfiniteQuery({
    queryKey: ["reports", report.url],
    queryFn: (fnParams) =>
      client.get<any[]>(fnParams.pageParam ?? report.url, { timeout: 60_000 }),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.replace("/api/", ""),
    initialPageParam: null as null | string,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      if ([401, 403, 404, 422, 500].includes(error.response?.status!))
        return false;
      return failureCount < 3;
    },

    enabled: report.status === ReportStatus.LOADING,
    gcTime: 0,
  });

  const data = useMemo(
    () => queryData?.pages.flatMap((d) => d.data),
    [queryData]
  );

  const totalEntries = queryData?.pages[0].meta?.total_entries ?? 0;
  const percentLoaded = totalEntries && data ? data.length / totalEntries : 0;

  const setStatus = (status: RStatus) =>
    dispatch(setReportStatus({ id: report.id, status }));

  const onDownload = async () => {
    try {
      const blob = await reportDefinition.onDownload(reportData);
      saveAs(blob, fullFileName);
      setStatus(ReportStatus.DOWNLOADED);
    } catch (error: any) {
      console.error(error);
      setRunTimeError(error.toString?.() ?? "Unknown error");
      setStatus(ReportStatus.FAILED);
    }
  };

  useEffect(() => {
    if (!data || report.status !== ReportStatus.LOADING) return;
    if (hasNextPage) {
      fetchNextPage();
    } else {
      setStatus(ReportStatus.COMPLETE);
      setReportData(
        reportDefinition.onComplete ? reportDefinition.onComplete(data) : data
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length, hasNextPage, report.status, reportDefinition]);

  useEffect(() => {
    if (error) setStatus(ReportStatus.FAILED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <ReportRow
      filename={fullFileName}
      report={report}
      error={error ?? runTimeError}
      percentLoaded={percentLoaded}
      onDownload={onDownload}
    />
  );
};

export default SingleReport;
