import Axios from "axios";
import JsonaService from "jsona";
import { TJsonApiBody } from "jsona/lib/JsonaTypes";

import { JsonApiJsonMapper, JsonApiModelMapper } from "./JsonApiMappers";
import { query } from "./clientHelpers";
import { ClientResponse, Options, RequestConfig, Transformer } from "./types";

type TFullJsonApiBody = TJsonApiBody & {
  meta: Record<string, any>;
  relationships?: string[];
  relationshipNames?: string[];
  links?: {
    prev?: string;
    next?: string;
  };
};

const apiClient = (options: Options = {}) => {
  const _globalHeaders = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    ...options.headers,
  };
  const _axios = Axios.create({
    baseURL: options.baseURL || "/api/",
    timeout: options.timeout || 30000,
  });

  const _serializers = {};
  const _deserializers = {};

  const _dataFormatter = new JsonaService({
    modelPropertiesMapper: new JsonApiModelMapper(_serializers),
    jsonPropertiesMapper: new JsonApiJsonMapper(_deserializers),
  });

  const _deserialize = (data: TFullJsonApiBody) => ({
    relationshipNames: Object.keys(data.relationships ?? {}),
    data: _dataFormatter.deserialize(data),
    meta: data.meta,
    links: data.links,
  });

  const _serialize = (data, includeNames?: string[]) => {
    return _dataFormatter.serialize({ stuff: data, includeNames });
  };

  const registerTransformer = (transformer: Transformer) => {
    const types = !Array.isArray(transformer.type)
      ? [transformer.type]
      : transformer.type;

    types.forEach((type) => {
      _serializers[type] = transformer.serialize;
      _deserializers[type] = transformer.deserialize;
    });
  };

  const _configDefaults = (config) => ({
    serializeBody: true,
    deserializeOutput: true,
    ...config,
  });

  async function get<R = any>(url: string, conf?: RequestConfig<true>): Promise<ClientResponse<R>>; // prettier-ignore
  async function get<R = any>(url: string, conf:  RequestConfig<false>): Promise<R>; // prettier-ignore
  async function get<R = any>(
    url: string,
    conf?: RequestConfig<boolean>
  ): Promise<R | ClientResponse<R>> {
    const config = _configDefaults(conf);
    const headers = { ..._globalHeaders, ...config.headers };
    const params = { ...config.params };

    const { data } = await _axios.get(url, {
      headers,
      params,
      paramsSerializer: (p) => query(p),
      ...config.axiosOptions,
    });

    return config.deserializeOutput ? _deserialize(data) : data;
  }

  function createOrUpdate(method: "post" | "put" | "patch" | "update") {
    async function withMethod<R = any>(url: string, body?: Record<any, any>, conf?: RequestConfig<true>): Promise<ClientResponse<R>>; // prettier-ignore
    async function withMethod<R = any>(url: string, body:  Record<any, any>, conf:  RequestConfig<false>): Promise<R>; // prettier-ignore
    async function withMethod<R = any>(
      url: string,
      body?: Record<any, any>,
      config: RequestConfig<boolean> = {}
    ): Promise<ClientResponse<R> | R> {
      config = _configDefaults(config);
      const headers = { ..._globalHeaders, ...config.headers };
      const params = { ...{}, ...config.params };

      const bodyWithInferedType = {
        __type: url.split("/")[0],
        ...body,
      };

      const serialData = !body
        ? {}
        : config.serializeBody
        ? _serialize(bodyWithInferedType, config.includeNames)
        : body;

      const { data } = await _axios({
        method: method,
        url: url,
        data: serialData,
        headers,
        params,
        paramsSerializer: (p) => query(p),
        ...config.axiosOptions,
      });

      return config.deserializeOutput ? _deserialize(data) : data;
    }
    return withMethod;
  }

  async function remove(url: string, config: RequestConfig = {}) {
    const headers = { ..._globalHeaders, ...config.headers };
    const params = { ...{}, ...config.params };

    await _axios.delete(url, {
      headers,
      params,
      paramsSerializer: (p) => query(p),
      ...config.axiosOptions,
    });

    return { id: url.split("/").at(-1) as string };
  }

  return {
    get,
    post: createOrUpdate("post"),
    patch: createOrUpdate("patch"),
    put: createOrUpdate("put"),
    // Alias for patch, for backwards compatibility
    update: createOrUpdate("patch"),
    remove,
    delete: remove,
    headers: _globalHeaders,
    registerTransformer,
    deserialize: _deserialize,
  };
};

export default apiClient;
