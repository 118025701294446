import { useCallback } from "react";
import { NavigateOptions, Path, useNavigate } from "react-router";

const useSetLocation = () => {
  const navigate = useNavigate();
  return useCallback(
    (
      location: {
        pathname?: string;
        search?: `?${string}` | "";
        hash?: `#${string}` | "";
      },
      navigateOptions?: NavigateOptions
    ) => {
      const newLocation: Path = {
        ...window.location,
        ...location,
      };
      navigate(
        newLocation.pathname + newLocation.search + newLocation.hash,
        navigateOptions
      );
    },
    [navigate]
  );
};

export default useSetLocation;
