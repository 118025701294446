/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import _ from "lodash";

import { CheckboxInput } from "@components/Forms/ControlledInputs";
import { ItemPreviewImageButton } from "@features/items";
import { Button } from "@features/ui";
import { Item } from "@models";

import { getVariantName } from "../helpers";
import useGetVariantAvailableInventory from "./hooks/useGetVariantAvailableInventory";

type VariantSelectionModalProps = {
  items: Item[];
  orderType: "on-demand" | "from-inventory" | "pre-order";
  orderSetVariants: any[];
  onClose: () => void;
  createOrUpdateOrderSet: (variantIds: string[]) => Promise<void>;
};

const filterActive = (items) => _.filter(items, "isActive");

const VariantSelectionModal = ({
  onClose,
  items,
  createOrUpdateOrderSet,
  orderSetVariants,
  orderType,
}: VariantSelectionModalProps) => {
  const isInventoryOrder = orderType === "from-inventory";

  const getVariantAvailableInventory = useGetVariantAvailableInventory();

  const existingVariantIds = orderSetVariants.map((osv) => osv.variant.id);

  const variantInventories = isInventoryOrder
    ? Object.fromEntries(
        items.flatMap((item) =>
          item.variants.map((variant) => [
            variant.id,
            getVariantAvailableInventory(variant, { includeNational: true }),
          ])
        )
      )
    : {};
  const outOfStockIds = Object.keys(variantInventories).filter(
    (id) => variantInventories[id] === 0
  );

  // Ids of variants from items with only a single, default, variant
  const onlyChildVariantIds = items.flatMap((item) =>
    item.variants.length === 1 ? [item.variants[0].id] : []
  );

  // These can't be changed by the user
  const immutableValues = {
    // set all out of stock variants to false
    ...Object.fromEntries(outOfStockIds.map((id) => [id, false])),
    // set all in-cart variants to true
    ...Object.fromEntries(existingVariantIds.map((id) => [id, true])),
    // set all only-child variants to true
    ...Object.fromEntries(onlyChildVariantIds.map((id) => [id, true])),
  };
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: immutableValues,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectItemVariants = (item: Item) => {
    const formValues = getValues();

    const variantIds = filterActive(item.variants).map((variant) => variant.id);
    const inStockVariantIds = _.difference(variantIds, outOfStockIds);
    const allItemVariantsSelected =
      _.difference(
        inStockVariantIds,
        Object.keys(formValues).filter((key) => formValues[key])
      ).length === 0;

    const variantIdObject = Object.fromEntries(
      inStockVariantIds.map((id) => [id, !allItemVariantsSelected])
    );
    reset((data) => ({ ...data, ...variantIdObject, ...immutableValues }));
  };

  const handleAddToCart = async (data) => {
    setLoading(true);
    const selectedVariantIds = Object.keys(data).filter((key) => data[key]);
    const variantIdsToCreate = _.difference(
      selectedVariantIds,
      existingVariantIds
    );
    await createOrUpdateOrderSet(variantIdsToCreate);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle tw="flex justify-between items-start">
        Select Variants
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form tw="space-y-6">
          {items.map((item) => {
            const activeVariants = filterActive(item.variants);

            if (activeVariants[0].selectedVariantOptions.length === 0)
              return null;

            return (
              <div key={item.id}>
                <div tw="flex gap-4 mb-2 items-center">
                  <ItemPreviewImageButton item={item} tw="w-16 m-0" />
                  <div>
                    <h3 tw="text-neutral-700 font-medium">
                      {item.comment} / {item.type}
                    </h3>
                    <a
                      href="#!"
                      role="button"
                      tw="inline-block mb-2 text-sm text-primary-600 underline"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelectItemVariants(item);
                      }}
                    >
                      Select All
                    </a>
                  </div>
                </div>
                <div tw="grid grid-cols-2 gap-2">
                  {_.sortBy(activeVariants, "orderPosition").map((variant) => {
                    const variantInventory =
                      isInventoryOrder && variantInventories[variant.id];
                    const inCart = existingVariantIds.includes(variant.id);
                    return (
                      !variant.isDefault && (
                        <div
                          key={variant.id}
                          tw="flex flex-wrap items-center justify-between gap-x-2
                                  rounded-lg border border-neutral-200 p-2"
                        >
                          <CheckboxInput
                            tw="-my-2"
                            control={control}
                            labelProps={{ style: tw`text-sm!` }}
                            name={variant.id}
                            label={getVariantName(variant)}
                            disabled={inCart || variantInventory === 0}
                          />
                          <div tw="text-sm text-neutral-500 whitespace-nowrap text-right">
                            {!inCart &&
                              variantInventory === 0 &&
                              "Out of stock"}
                            {inCart && "In Cart"}

                            {Number(variantInventory) > 0 &&
                              variantInventory !== Infinity && // Infinity inventory are ignored
                              !inCart && (
                                <span tw=" text-primary-800">
                                  {variantInventory} available
                                </span>
                              )}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            );
          })}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSubmit(handleAddToCart)}
          loading={loading}
        >
          Add to Cart
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VariantSelectionModal;
