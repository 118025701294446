/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";

import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Tooltip,
} from "@mui/material";

import _, { xor } from "lodash";
import { updateItemSelection } from "src/redux/slices/items/itemSlice";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { useAuthUser, useToggleFavoriteItemMutation } from "@features/auth";
import { ResourceError } from "@features/errors";
import useGetVariantAvailableInventory from "@features/orders/ordering/hooks/useGetVariantAvailableInventory";
import { FaIcon } from "@features/ui";

import { ItemCatalogProps } from "./ItemCatalogView";
import ItemOptionsCount from "./ItemOptionsCount";
import ItemPreviewImageButton from "./ItemPreviewImageButton";
import VariantAllocationBreakdownTooltip from "./VariantAllocationBreakdownTooltip";

const ItemCatalogGrid = ({
  showCheckbox,
  isRowDisabled,
  getCheckboxText,
  rowActions,
  rows,
  isLoading,
  isFetching,
  isPlaceholderData,
  hasNextPage,
  fetchNextPage,
  error,
  orderView,
  hideBrands,
}: ItemCatalogProps) => {
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: any) => state.items);
  const { favoriteItemIds } = useAuthUser();
  const toggleFavoriteItemMutation =
    useToggleFavoriteItemMutation(favoriteItemIds);
  const toggleSelection = (id) =>
    dispatch(updateItemSelection({ items: xor(selectedItems, [id]) }));

  const getVariantAvailableInventory = useGetVariantAvailableInventory();

  const fetchNext = () =>
    !isLoading && !isFetching && hasNextPage && fetchNextPage?.();
  const scrollRef = useBottomScrollListener(fetchNext, {
    offset: 500,
    triggerOnNoScroll: true,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  }) as any;

  return (
    <div tw="relative overflow-hidden h-full border-t-2 border-neutral-100">
      {isLoading && !isPlaceholderData && <CircularProgress tw="m-6" />}
      {error && (
        <div tw="mb-4">
          <ResourceError error={error} />
        </div>
      )}
      <div
        css={[
          tw`
          grid [grid-template-columns: repeat(auto-fill, minmax(14em, 1fr))] 
          gap-[2px] transition-opacity bg-neutral-100 overflow-y-auto h-full
      `,
          isPlaceholderData && tw`opacity-50 pointer-events-none`,
        ]}
        ref={scrollRef}
      >
        {rows.map((item) => {
          const itemQty =
            orderView === "from-inventory"
              ? _.sumBy(item.variants, (v) =>
                  getVariantAvailableInventory(v, { includeNational: true })
                )
              : 0;
          return (
            <div
              key={item.id}
              className="group"
              tw="bg-white p-6 overflow-visible flex flex-col justify-between gap-2"
            >
              <div tw="relative">
                {showCheckbox && (
                  <FormControlLabel
                    css={{
                      "&": tw`absolute left-0 z-10 rounded-md -top-3 bg-white/90`,
                      ".MuiFormControlLabel-label": tw`pr-3 empty:pr-0`,
                    }}
                    control={
                      <Checkbox
                        tw="my-0"
                        checked={selectedItems.includes(item.id)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => toggleSelection(item.id)}
                        disabled={isRowDisabled?.(item) ?? false}
                      />
                    }
                    label={getCheckboxText?.(item) ?? ""}
                  />
                )}
                <ItemPreviewImageButton
                  item={item}
                  size="medium"
                  tw="w-full! -mt-2"
                />
                <div tw="flex gap-1 items-center">
                  <Tooltip title="Favorite Item">
                    <Checkbox
                      className="group"
                      tw="-my-2 -mr-2"
                      edge="start"
                      checked={favoriteItemIds.includes(+item.id)}
                      size="small"
                      icon={
                        <FavoriteBorderRounded tw="text-lg text-neutral-400 group-hover:text-primary-500" />
                      }
                      checkedIcon={
                        <FavoriteRounded tw="text-lg text-primary-500" />
                      }
                      onClick={(e) => e.stopPropagation()}
                      onChange={() =>
                        toggleFavoriteItemMutation.mutate(item.id)
                      }
                    />
                  </Tooltip>
                  <h5 tw="text-neutral-600 font-medium tracking-wide">
                    {item.itemNumber}
                  </h5>
                  {item.couponBarcodeId && (
                    <Tooltip title="Coupon Offer Code" placement="top" arrow>
                      <div tw="inline-flex ml-2 tracking-wide gap-0.5 items-center text-sm text-neutral-600 opacity-75 transition-opacity hover:opacity-100">
                        <FaIcon
                          variant="solid"
                          icon="tag"
                          tw="text-primary-500 text-base"
                        />
                        {item.couponBarcodeId}
                      </div>
                    </Tooltip>
                  )}
                </div>
                {!hideBrands && (
                  <h6 tw="font-medium text-neutral-900 leading-snug tracking-wide">
                    {item.brands.length > 1
                      ? "Multi-Brand"
                      : item.brands[0].name}
                  </h6>
                )}
                <div tw="text-sm mt-2 text-neutral-600 leading-tight [text-wrap: pretty]">
                  <span tw="font-medium">{item.itemTypeName} / </span>
                  <span tw="text-neutral-500">{item.comment}</span>
                </div>
                {item.variants!.length > 1 && <ItemOptionsCount item={item} />}
              </div>
              <div tw="flex flex-col gap-2">
                <div tw="flex justify-start items-baseline gap-2">
                  <div tw="font-medium">
                    {formatMoneyString(item.mostRecentEstimatedCost)}
                  </div>
                  <div tw="text-sm text-neutral-500">
                    {item.qtyPerPack === 1 ? (
                      <span>Each</span>
                    ) : (
                      <>
                        <span>packs of {item.qtyPerPack}</span>
                      </>
                    )}
                  </div>
                </div>
                {itemQty > 0 && (
                  <VariantAllocationBreakdownTooltip
                    variants={item.variants}
                    qty={itemQty}
                    suffix=" available"
                    tw="px-3 -mx-3"
                  />
                )}
                <div tw="-m-3 mt-0 flex flex-col">{rowActions?.(item)}</div>
              </div>
            </div>
          );
        })}

        {rows.length === 0 && !isLoading && (
          <div tw="text-xl text-neutral-500 p-6">
            No items found matching your search
          </div>
        )}
      </div>

      <div tw="h-1 w-full absolute left-0 bottom-0">
        {isFetching && !isLoading && <LinearProgress />}
      </div>
    </div>
  );
};

export default ItemCatalogGrid;
