import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { SubState } from "@models/SubState";
import client, { RequestParams } from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

export const subStatesKeyFactory = createQueryKeys("sub-states", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<SubState[]>("sub-states", { params }).then((res) => res.data),
  }),
});

export const useSubStates = (
  params?: RequestParams,
  opts?: QueryOptions<SubState[]>
) => {
  return useQuery({
    ...subStatesKeyFactory.list(params),
    staleTime: Infinity,
    ...opts,
  });
};
