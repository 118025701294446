/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { IconButton } from "@mui/material";

import _ from "lodash";
import { ALL_ROLES, SUPPLIER } from "src/constants/permissions";

import useRoleIs from "@utils/useRoleIs";

import { Contained } from "../StyledComponents";
import OnPremiseRetailSelector from "./OnPremiseRetailSelector";
import RegionSelector from "./RegionSelector";
import StagingIdentifier from "./StagingIdentifier";

const Header = ({ open, setOpen }) => {
  const territories = useSelector((state) => state.user.territories);
  const roleIs = useRoleIs();

  return (
    <header tw="sticky top-0 z-50 h-[var(--header-height)]">
      <div tw="bg-white/75 backdrop-blur-md py-2">
        <Contained tw="flex items-center justify-between h-full">
          <div tw="flex items-center gap-4">
            <IconButton
              tw="text-neutral-500 -my-2"
              onClick={() => setOpen(!open)}
              edge="start"
            >
              {!open ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
            <StagingIdentifier />
            {/* {(!isMobile || displaySelectorsAbove) && <BreadcrumbTrail />} */}
          </div>
          <div tw="flex items-center lg:gap-x-3">
            {/*
              <DraftOrderMenu />*/}

            <div
              id="tutorial-channel-territory"
              tw="flex items-center lg:gap-x-3"
            >
              {roleIs(_.without(ALL_ROLES, SUPPLIER)) && (
                <OnPremiseRetailSelector />
              )}
              {territories && territories.length > 0 && <RegionSelector />}
            </div>
          </div>
        </Contained>
      </div>
    </header>
  );
};

export default Header;
