import { Link } from "react-router";

import { ExitToApp } from "@mui/icons-material";

import { Button } from "@features/ui";

const BackButton = ({ url = "/reports" }: { url?: string }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<ExitToApp style={{ transform: "rotate(180deg)" }} />}
      component={Link}
      to={url}
    >
      Back
    </Button>
  );
};

export default BackButton;
