export const SUPPLIER = "supplier";

export const PLANNING_TOOL_SUPPLIER = "planning-tool-supplier";
export const READ_ONLY = "read-only";
export const COMPLIANCE = "compliance";
export const FREIGHT_FORWARDER = "freight-forwarder";

export const ALL_REGION_ROLES = [
  "super",
  "purchaser",
  "select-purchaser",
  "read-only",
  "compliance",
] as const;

export const NON_SUPPLIER_ROLES = [
  "field1",
  "field2",
  "purchaser",
  "select-purchaser",
  "super",
  "read-only",
  "compliance",
] as const;

export const SECONDARY_ORDER_WINDOWS = [
  "field1",
  "field2",
  "purchaser",
  "super",
] as const;

export const SUPPLIER_ROLES = [SUPPLIER, PLANNING_TOOL_SUPPLIER] as const;

export const RFQ_ROLES = [
  "field2",
  "purchaser",
  "select-purchaser",
  "super",
] as const;

export const PLANNING_TOOL_ROLES = [
  "purchaser",
  "select-purchaser",
  "super",
  "field2",
  PLANNING_TOOL_SUPPLIER,
] as const;

export const ALL_ROLES = [
  ...NON_SUPPLIER_ROLES,
  ...SUPPLIER_ROLES,
  FREIGHT_FORWARDER,
];

export const ADMIN_ROLES = ["super", "purchaser", "select-purchaser"] as const;

export const ORDER_TO_NATIONAL_INVENTORY = ADMIN_ROLES;

export const ORDER_TO_INVENTORY_FROM_ON_DEMAND = [
  ...ADMIN_ROLES,
  "field2",
] as const;

export const PURCHASERS_AND_SUPPLIERS = [
  ...ADMIN_ROLES,
  ...SUPPLIER_ROLES,
] as const;

export const PURCHASER_ADMIN = [...ADMIN_ROLES, READ_ONLY] as const;
