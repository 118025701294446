/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import { tooltipClasses } from "@mui/material";

import _ from "lodash";

import { getVariantName } from "@features/orders";
import { useCurrentRegionalTerritorySubStates } from "@features/states";
import { PaperTooltip } from "@features/ui";
import { FaIcon } from "@features/ui";
import { Variant } from "@models/Variant";

const SubStateAllocationBreakdown = ({
  variants,
  subStateIds,
  isAllRegions,
}: {
  variants: Variant[];
  subStateIds: string[];
  isAllRegions: boolean;
}) => {
  const variantAllocs = variants
    .filter((v) => v.isActive)
    .map((v) => ({
      name: getVariantName(v),
      variantAllocations: v.variantAllocations.filter(
        (va) =>
          va.subState &&
          (isAllRegions || subStateIds.includes(va.subState.id)) &&
          va.availableToOrderQty > 0
      ),
    }));

  const hasVariants = variantAllocs.some((vAlloc) => vAlloc.name);
  const hasAllocations = variantAllocs.some(
    (v) => v.variantAllocations.length > 0
  );

  const unallocated = _.sumBy(variants, "nationalAvailableToOrderQty");

  return (
    <div tw="space-y-2">
      {unallocated > 0 && (
        <div tw="text-neutral-600">
          <span tw="font-medium text-primary-600 bg-primary-50 px-1 py-0.5 rounded-md">
            {unallocated}
          </span>{" "}
          units available nationally
        </div>
      )}
      {hasAllocations && (
        <table
          tw="table-auto border-collapse w-full text-neutral-600"
          css={{
            "th, td": tw`px-2 py-1 text-left align-bottom border`,
            th: tw`text-neutral-800`,
          }}
        >
          <thead>
            <tr>
              {hasVariants && <th>Variant</th>}
              <th>Allocation</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            {variantAllocs.map(({ name, variantAllocations }) =>
              variantAllocations.map((va, index) => (
                <tr key={index}>
                  {hasVariants && <td>{name || "-"}</td>}
                  <td>{va.subStateName ?? va.territoryName}</td>
                  <td>{va.availableToOrderQty}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

const VariantAllocationBreakdownTooltip = ({
  qty,
  variants,
  suffix,
  className,
}: {
  qty: number;
  variants: Variant[];
  suffix?: string;
  className?: string;
}) => {
  const { isRegional, subStateIds, isAllRegions } =
    useCurrentRegionalTerritorySubStates();

  if (!isRegional || qty === 0)
    return <>{[qty, suffix].filter((v) => !!v).join(" ")}</>;

  return (
    <PaperTooltip
      title={
        <SubStateAllocationBreakdown
          variants={variants}
          subStateIds={subStateIds}
          isAllRegions={isAllRegions}
        />
      }
      css={{
        [`& .${tooltipClasses.tooltip}`]: tw`max-w-none`,
      }}
      placement="bottom-start"
    >
      <div
        tw="bg-neutral-100 py-1 px-2 rounded-lg flex gap-2 justify-between items-center  hover:bg-neutral-200"
        className={className}
      >
        <div>
          <span tw="text-neutral-800">{qty}</span>
          {suffix}
        </div>
        <FaIcon icon="binoculars" variant="solid" tw="text-neutral-400" />
      </div>
    </PaperTooltip>
  );
};

export default VariantAllocationBreakdownTooltip;
