/** @jsxImportSource @emotion/react */
import "twin.macro";

import { ComponentProps } from "react";

import { Card } from "@mui/material";

import { DefaultError } from "@tanstack/react-query";

import { FaIcon } from "@features/ui";
import { parseApiErrorMessage } from "@services/api";

export const ResourceError = ({
  error,
  ...props
}: { error: DefaultError | Error } & ComponentProps<typeof Card>) => {
  return (
    <Card
      tw="flex gap-3 items-start bg-red-50 border-red-100 p-6 max-w-md"
      {...props}
    >
      <FaIcon
        icon="triangle-exclamation"
        variant="solid"
        tw="text-red-600 mt-1"
      />
      <div>
        <h3 tw="text-lg text-red-600">Error fetching resource:</h3>
        <p tw="text-red-800">{parseApiErrorMessage(error)}</p>
      </div>
    </Card>
  );
};
