/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import { CircularProgress, Tooltip } from "@mui/material";

import _ from "lodash";
import { ORDER_TO_INVENTORY_FROM_ON_DEMAND } from "src/constants/permissions";

import { AddressSearch } from "@features/addresses";
import useRoleIs from "@utils/useRoleIs";

import { useCurrentOrderSet } from "../data/CurrentOrderSetContext";
import useAddOrders from "../data/mutations/useAddOrders";
import FavoriteAddressSetsMenuButton from "./FavoriteAddressSetsMenuButton";
import RestockOrdersButton from "./RestockOrdersButton";

const OrderSetAddressSection = () => {
  const roleIs = useRoleIs();
  const addOrders = useAddOrders();
  const { orderSet, orders } = useCurrentOrderSet();
  const orderType = orderSet.type;
  const [queuedAddressIds, setQueuedAddressIds] = useState<string[]>([]);

  const handleCreateOrders = (addressIds: string[]) => {
    setQueuedAddressIds([...queuedAddressIds, ...addressIds]);
    addOrders.mutate(addressIds, {
      onError: () => setQueuedAddressIds((ids) => _.xor(ids, addressIds)),
    });
  };

  const hasProgram = !!orderSet.program;
  const allowToInventoryOrders =
    orderType === "pre-order" ||
    (orderType === "on-demand" &&
      roleIs(ORDER_TO_INVENTORY_FROM_ON_DEMAND) &&
      hasProgram);

  const reasonForDisabled =
    orderType === "on-demand" && !hasProgram
      ? "The order-set must have a program to add re-stock orders."
      : "Cannot add re-stock orders for on-demand order-sets.";

  const orderSetAddressIds = orders.flatMap((o) =>
    o.address ? [o.address.id] : []
  );
  const loadingOrders = _.difference(queuedAddressIds, orderSetAddressIds);

  useEffect(() => {
    if (loadingOrders.length === 0) {
      setQueuedAddressIds([]);
    }
  }, [loadingOrders.length]);

  return (
    <div tw="space-y-3">
      <AddressSearch
        multiple
        autoFocus
        currentTerritory
        onChange={(addresses) =>
          handleCreateOrders([addresses.at(-1)?.id].filter(Boolean) as string[])
        }
        renderTags={() => null}
        getOptionDisabled={(option) =>
          orderSetAddressIds.includes(option.id) ||
          queuedAddressIds.includes(option.id)
        }
      />
      <div tw="flex gap-2 mt-3">
        <div tw="flex-1">
          <FavoriteAddressSetsMenuButton
            handleAddAddressSet={(set) =>
              handleCreateOrders(set.addresses.map((a) => a.id))
            }
          />
        </div>
        {!roleIs("field1", "planning-tool-supplier") && (
          <Tooltip title={allowToInventoryOrders ? "" : reasonForDisabled}>
            <div tw="flex-1">
              <RestockOrdersButton disabled={!allowToInventoryOrders} />
            </div>
          </Tooltip>
        )}
      </div>
      {loadingOrders.length > 0 && (
        <div tw="bg-neutral-100 p-3 rounded-lg text-neutral-600 tracking-wide text-base flex items-center justify-between">
          <div>Creating orders...</div>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};

export default OrderSetAddressSection;
