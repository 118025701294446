import { kebabCaseKeys } from "src/utility/utilityFunctions";

import { createSlice } from "@reduxjs/toolkit";

import { axiosPatch, axiosPost, axiosPut } from "../../api/axiosCalls";
import {
  cancelCompItem,
  updateCompItemSelection,
} from "./compliance/complianceItemsSlice";
import { setError } from "./errorSlice";
import { buildOrderVariantPatch } from "./ordering/helpers";
import { fetchOrder, setOrderHasUpdated } from "./ordering/orderHistorySlice";

/*
The patch order slice handles the loading state for all cells in the order grid, and also handles
the loading state for smaller operations. When isLoading is true, most views will display a
small loading indicator (<OrderPatchLoading />) to the user.
*/

let initialState = {
  isLoading: false,
  cellsLoading: [],
  cellsError: [],
  error: null,
};

const patchOrderSlice = createSlice({
  name: "patchOrder",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },

    patchSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },

    setFailure(state, action) {
      const { error, cell } = action.payload;
      if (cell) {
        let currentErrors = [...state.cellsError];
        let currentLoading = [...state.cellsLoading];
        let currentCell = currentLoading.find(
          (c) => c.id === cell.id && c.orderNumber === cell.orderNumber
        );
        currentLoading.splice(currentLoading.indexOf(currentCell), 1);
        currentErrors.push(cell);
        state.cellsError = currentErrors;
        state.cellsLoading = currentLoading;
        state.isLoading = false;
      } else {
        state.isLoading = false;
        state.error = error;
      }
    },
  },
});

export const { setIsLoading, patchSuccess, setFailure } =
  patchOrderSlice.actions;

export default patchOrderSlice.reducer;

export const updateOrderVariantQty = (id, qty) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const patchData = buildOrderVariantPatch(id, qty);
    const response = await axiosPatch(`/api/order-variants/${id}`, patchData);
    if (response.error) throw response.error;
    dispatch(patchSuccess());
    dispatch(setOrderHasUpdated({ value: true, newOrderId: null }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
  }
};

//cancels all order-variants sent with a status of compliance canceled
export const complianceCancelOrderVariants =
  (itemIds, cancelNote) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      for (const id of itemIds) {
        const postData = {
          "cancelation-type": "compliance",
          "cancelation-note": cancelNote,
        };
        const response = await axiosPost(
          `/api/order-variants/${id}/cancel`,
          postData
        );
        if (response.error) throw response.error;
        dispatch(cancelCompItem({ id: id }));
      }

      dispatch(updateCompItemSelection({ selectedItems: [] }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const cancelOrderById = (id, type, note) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const postData = { "cancelation-type": type, "cancelation-note": note };
    const response = await axiosPost(`/api/orders/${id}/cancel`, postData);
    if (response.error) throw response.error;
    dispatch(fetchOrder(id));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
  }
};

export const cancelOrderVariantById =
  (id, type, note, orderId) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const postData = {
        "cancelation-type": type,
        "cancelation-note": note,
      };
      const response = await axiosPost(
        `/api/order-variants/${id}/cancel`,
        postData
      );
      if (response.error) throw response.error;
      dispatch(fetchOrder(orderId));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const updateOrderVariantAddress =
  (id, addressId) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const postData = { "address-id": addressId };
      const response = await axiosPost(
        `/api/order-variants/${id}/update-address`,
        postData
      );
      if (response.error) throw response.error;
      dispatch(
        setOrderHasUpdated({
          value: true,
          newOrderId: response.data.order.id,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const overrideOrderVariantCompliance =
  (id, note) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const response = await axiosPost(`/api/order-variants/${id}/override`, {
        note: note,
      });
      if (response.error) throw response.error;
      dispatch(
        setOrderHasUpdated({
          value: true,
          newOrderId: null,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const updateOrderAddress = (id, addressId) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const postData = { "address-id": addressId };
    const response = await axiosPost(
      `/api/orders/${id}/update-address`,
      postData
    );
    if (response.error) throw response.error;
    dispatch(
      setOrderHasUpdated({
        value: true,
        newOrderId: null,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
  }
};

export const updateOrderToInventoryAllocation =
  (id, territoryId, subStateId, callback) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const data = kebabCaseKeys({
        data: {
          type: "order",
          relationships: {
            territory: {
              data: {
                type: "territory",
                id: territoryId,
              },
            },
            "sub-state": {
              data: subStateId ? { type: "sub-state", id: subStateId } : null,
            },
          },
        },
      });
      const response = await axiosPut(`/api/orders/${id}`, data);
      if (response.error) throw response.error;
      dispatch(
        setOrderHasUpdated({
          value: true,
          newOrderId: null,
        })
      );
      dispatch(patchSuccess());
      callback?.();
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };
