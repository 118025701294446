/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router";

import { Chip } from "@mui/material";

import { Contained } from "src/components/StyledComponents";
import { PLANNING_TOOL_ROLES } from "src/constants/permissions";
import { setIsOrdering } from "src/redux/slices/appSlice";
import { setFiltersOpen } from "src/redux/slices/filterSlice";
import {
  updateCurrentChannel,
  updateCurrentTerritory,
} from "src/redux/slices/users/userSlice";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";
import { OrderSetActionBar, OrderSetOverview } from "@features/orders";
import ExportOrderSetButton from "@features/orders/orderSet/ExportOrderSetButton";
import OrderSetApproveDenyBar from "@features/orders/orderSet/OrderSetApproveDenyBar";
import OrderSetTable from "@features/orders/orderSet/OrderSetTable";
import {
  CurrentOrderSetContextProvider,
  useCurrentOrderSet,
} from "@features/orders/orderSet/data/CurrentOrderSetContext";
import { OrderSetBudgetImpactAccordion } from "@features/orders/orderSetOverview/OrderSetBudgetImpact";
import { Button, PageTitle } from "@features/ui";
import { FaIcon } from "@features/ui";
import { OrderSet } from "@models/OrderSet";
import useRoleIs from "@utils/useRoleIs";

const OrderPageContent = () => {
  const dispatch = useDispatch();
  const { orderSet } = useCurrentOrderSet();
  const { hash } = useLocation();
  const roleIs = useRoleIs();
  const { currentChannel, currentTerritory } = useSelector(
    (state: any) => state.user
  );

  useEffect(() => {
    dispatch(updateCurrentChannel({ channel: orderSet.channel }));
    if (orderSet.territory && orderSet.territory.id !== currentTerritory) {
      dispatch(updateCurrentTerritory({ territory: orderSet.territory.id }));
    }
  }, [dispatch, currentChannel, currentTerritory, orderSet]);

  const addItemsUrl = `/orders/items/${
    orderSet.type === "from-inventory"
      ? "inventory"
      : orderSet.programType === "National Coupon"
      ? "on-demand#national-coupons"
      : "on-demand"
  }`;

  const backButtonUrl =
    {
      "#overview": "#",
      "#approvals": "/orders/approvals",
      "#rollup": "/rollup",
      "#rollup-overview": "#rollup",
    }[hash] ?? addItemsUrl;

  const canNavigateToProgram =
    !!orderSet.program && roleIs(PLANNING_TOOL_ROLES as any);

  return (
    <div tw="min-h-[calc(100vh - var(--header-height))]">
      <Contained tw="space-y-6">
        <div tw="flex justify-between items-center">
          <PageTitle
            fallbackUrl={backButtonUrl}
            title={
              {
                "on-demand": "Current On-Demand Order",
                "pre-order": "Current Pre-Order",
                "from-inventory": "Current Inventory Order",
              }[orderSet.type]
            }
          >
            {orderSet.type !== "from-inventory" && (
              <Chip
                tw="ml-3 flex items-center px-3"
                label={
                  orderSet.anaplanPrograms.length > 0
                    ? getAnaplanProgramsLabel(orderSet.anaplanPrograms)
                    : orderSet.programName ?? "Miscellaneous Program"
                }
                {...(canNavigateToProgram && {
                  component: Link,
                  clickable: true,
                  to: `/planning/programs/${orderSet.program!.id}`,
                  target: "_blank",
                  icon: <FaIcon icon="external-link" />,
                })}
              />
            )}
          </PageTitle>
          <div tw="flex items-center gap-2">
            {["inactive", "in-progress"].includes(orderSet.status) && (
              <Button
                component={Link}
                variant="contained"
                color="secondary"
                to={addItemsUrl}
                startIcon={<FaIcon icon="plus" />}
              >
                Add Items
              </Button>
            )}
            <ExportOrderSetButton />
          </div>
        </div>
        {hash.includes("overview") ? (
          <OrderSetOverview />
        ) : (
          <>
            <OrderSetBudgetImpactAccordion />
            <OrderSetTable />
            {orderSet.type !== "pre-order" &&
            ["#approvals", "#rollup"].includes(hash) ? (
              <OrderSetApproveDenyBar backUrl={backButtonUrl} />
            ) : (
              <OrderSetActionBar />
            )}
          </>
        )}
      </Contained>
    </div>
  );
};

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onOrderSetDelete = (set: OrderSet) => {
    const addItemsUrl = `/orders/items/${
      set.type === "from-inventory" ? "inventory" : "on-demand"
    }`;

    navigate(set.type === "pre-order" ? "/rollup" : addItemsUrl);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    dispatch(setIsOrdering(true));
    return () => {
      dispatch(setIsOrdering(false));
    };
  }, [dispatch]);

  return (
    <CurrentOrderSetContextProvider onOrderSetDelete={onOrderSetDelete}>
      <OrderPageContent />
    </CurrentOrderSetContextProvider>
  );
};

export default Order;
