/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router";

import { Button, Tab, Tabs } from "@mui/material";

import _ from "lodash";
import { useNoFetch } from "src/hooks/UtilityHooks";
import { formatDateString } from "src/utility/utilityFunctions";

import PrintTableButton from "@components/SmartTable/PrintTableButton";
import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import {
  OrderHistoryOrderTable,
  OrderHistoryVariantTable,
  usePaginatedOrderVariantsQuery,
  usePaginatedOrdersQuery,
} from "@features/orders";
import { RequestReportButton, useReport } from "@features/reports";
import { FaIcon, PageTitle } from "@features/ui";
import { useSetLocation } from "@services/reactRouterDom";

import FourOhFour from "./FourOhFour";

const useOrderHistoryParams = () => {
  const [f] = useFilterParams();
  const { channel } = useSelector((state: any) => state.app);

  const boolOrderStatusFilters = {
    "order-canceled": "hasOrderCanceledOrderVariants",
    "compliance-canceled": "hasComplianceCanceledOrderVariants",
    destroyed: "hasDestroyedOrderVariants",
  };

  const boolFilter = boolOrderStatusFilters[f.orderStatus];

  const statusFilters = boolFilter
    ? { [boolFilter]: true }
    : { status: f.orderStatus };

  const params = {
    filter: {
      [f.searchBy ?? "itemNumber"]: f.q,
      ...(f.orderStatus !== "draft" &&
        f.fromDate &&
        f.toDate && {
          [`${f.dateRangeType ?? "submittedAt"}Range`]: `${formatDateString(
            f.fromDate
          )} - ${formatDateString(f.toDate)}`,
        }),
      type: f.orderType,
      orderCalendarMonthIds: f.orderCalendarMonths,
      brandIds: f.brands,
      programIds: f.programs,
      itemTypeIds: f.itemTypes,
      stateIds: f.states,
      budgetIds: f.budgets,
      userIds: f.users,
      supplierIds: f.suppliers,
      businessUnitNames: f.businessUnits,
      channel: channel,
      territoryIds: f.territories,
      anaplanProgramType: f.anaplanProgramType,
      addressIds: f.addresses,
      ...statusFilters,
    },
    sort: f.sort,
  };
  return params;
};

const orderFilterNamesToOrderVariantFilterNames = (
  params: Record<string, any>
) => {
  const { filter, sort, ...rest } = params;

  const sortPrefix = sort?.startsWith("-") ? "-" : "";
  const sortKey = sort?.replace(/^-/, "");
  const mappedSortKey =
    {
      id: "order-id",
      "submitted-at": "order-submitted-at",
      "shipped-at": "order-shipped-at",
    }[sortKey] ?? sortKey;

  const mappedFilter = _.mapKeys(filter, (_val, key) => {
    return (
      {
        id: "orderId",
        type: "orderType",
        status: "orderStatus",
        submittedAtRange: "orderSubmittedAtRange",
        hasOrderCanceledOrderVariants: "isOrderCanceled",
        hasComplianceCanceledOrderVariants: "isComplianceCanceled",
        hasDestroyedOrderVariants: "isDestroyed",
      }[key] ?? key
    );
  });

  return {
    sort: sortPrefix + mappedSortKey,
    filter: mappedFilter,
    ...rest,
  };
};

const OrderHistoryByItem = ({ tableRef }) => {
  const orderParams = useOrderHistoryParams();
  const params = orderFilterNamesToOrderVariantFilterNames(orderParams);
  const tableData = usePaginatedOrderVariantsQuery(params);

  useReport("order-history-variant", [
    "order-variants",
    { ...params, page: { size: 100 } },
  ]);

  return <OrderHistoryVariantTable {...tableData} tableRef={tableRef} />;
};

const OrderHistoryByOrder = () => {
  const params = useOrderHistoryParams();
  const tableData = usePaginatedOrdersQuery(params);

  useReport("order-history-order", ["orders", params]);

  return <OrderHistoryOrderTable {...tableData} />;
};

const OrderHistoryNew = () => {
  const setLocation = useSetLocation();
  const { groupby } = useParams();

  const printTableRef = useRef<any>(null);

  const orderParams = useOrderHistoryParams();
  const params = orderFilterNamesToOrderVariantFilterNames(orderParams);
  useReport("order-history-sales-summary", [
    "order-variants",
    { ...params, page: { size: 100 } },
  ]);

  useNoFetch();

  if (!["item", "order"].includes(groupby as any)) {
    return <FourOhFour />;
  }

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex justify-between items-center gap-3 flex-wrap">
        <div tw="flex items-end gap-6">
          <PageTitle title="Order History" tw="m-0" />
          <Tabs
            value={groupby}
            onChange={(_e, v) =>
              setLocation(
                { pathname: `/orders/history/${v}` },
                { replace: true }
              )
            }
            tw="min-h-0"
          >
            <Tab
              label="Item"
              value={"item"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
            <Tab
              label="Order"
              value={"order"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
          </Tabs>
        </div>
        <div tw="flex gap-3 flex-wrap">
          <PrintTableButton printRef={printTableRef} />
          <RequestReportButton
            reportName={
              groupby === "item"
                ? "order-history-variant"
                : "order-history-order"
            }
          />
          <RequestReportButton
            reportName="order-history-sales-summary"
            startIcon={<FaIcon icon="file-pdf" tw="text-base" />}
            title="Sale Summary PDF"
          />
          <Button
            component={Link}
            to="/orders/history/c2m-order-history"
            tw="font-normal normal-case"
          >
            C2M Order History
          </Button>
        </div>
      </div>
      <TableCardContainer>
        <Filters
          slots={[
            "addresses",
            "anaplanProgramType",
            "brands",
            "businessUnits",
            "budgets",
            "dateRange",
            "itemTypes",
            "orderStatus",
            "orderType",
            "programs",
            "states",
            "suppliers",
            "territories",
            "users",
          ]}
          slotProps={{
            search: {
              searchBy: [
                "itemNumber",
                "id",
                "anaplanCode",
                "budgetId",
                "programId",
              ],
            },
            orderType: {
              orderTypeOptions: ["on-demand", "from-inventory", "to-inventory"],
            },
            dateRange: {
              // chipTextPrefix: "Submitted ",
              dateRangeTypes: ["submittedAt", "inMarketDate", "actualShipDate"],
            },
          }}
          alwaysShow={["orderStatus", "dateRange", "orderType"]}
          defaultValues={{
            searchBy: groupby === "item" ? "itemNumber" : "id",
            sort: "-id",
            orderStatus: "not-draft",
          }}
        />

        {groupby === "item" ? (
          <OrderHistoryByItem tableRef={printTableRef} />
        ) : (
          <OrderHistoryByOrder />
        )}
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default OrderHistoryNew;
