/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";

import { DoNotDisturbOn, Warning } from "@mui/icons-material";
import { Checkbox, FormControlLabel, IconButton, Tooltip } from "@mui/material";

import { addDays, endOfDay, isBefore } from "date-fns";
import _ from "lodash";
import { SmartTable, insertIf, showFirst } from "src/components/SmartTable";
import {
  Column,
  PaginatedResourceProps,
} from "src/components/SmartTable/SmartTable";
import { setOrderSetSelection } from "src/redux/slices/ordering/orderSetHistorySlice";
import {
  formatDateString,
  formatMoneyString,
  utcDate,
} from "src/utility/utilityFunctions";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";
import BudgetStatusChip from "@features/budgets/BudgetStatusChip";
import { displayDate } from "@features/reports";
import { FaIcon } from "@features/ui";
import { OrderSet } from "@models/OrderSet";
import useRoleIs from "@utils/useRoleIs";

import { displayOrderSetStatus, orderTypeLabel } from "../helpers";

type ApprovalsOrderSetTableProps = PaginatedResourceProps & {
  data: OrderSet[];
  view: "approvals" | "rollup";
  mutationPending?: boolean;
  handleDeny?: (id: string) => void;
};

const getBrands = (os: OrderSet): string[] =>
  _(os.orderSetVariants)
    .flatMap("variant.item.brands")
    .map("name")
    .uniq()
    .value();

const isOverDue = (os: OrderSet) =>
  os.orderCalendarMonth && os.status === "in-progress"
    ? isBefore(
        addDays(
          endOfDay(utcDate(os.orderCalendarMonth.purchasingCloseDate)),
          1
        ),
        endOfDay(new Date())
      )
    : false;

const costColumn = {
  align: "right" as const,
  render: (value) => formatMoneyString(value),
};

const ApprovalsOrderSetTable = ({
  data,
  view,
  mutationPending,
  handleDeny,
  ...tableProps
}: ApprovalsOrderSetTableProps) => {
  const dispatch = useDispatch();
  const { selected } = useSelector((state: any) => state.orderSetHistory);
  const toggleSelection = (id: string) =>
    dispatch(setOrderSetSelection(_.xor(selected, [id])));

  const onClickAll = () => {
    if (selected.length === data.length) {
      dispatch(setOrderSetSelection([]));
    } else {
      dispatch(setOrderSetSelection(data.map((os) => os.id)));
    }
  };

  const readOnly = useRoleIs()("read-only", "compliance");

  const columns: Column<OrderSet>[] = [
    ...insertIf<OrderSet>(!readOnly && view === "approvals", {
      id: "checkbox",
      label: (
        <Checkbox
          tw="-m-4 -ml-2"
          indeterminate={selected.length > 0 && selected.length < data.length}
          checked={data.length > 0 && selected.length === data.length}
          onChange={onClickAll}
          inputProps={{ "aria-label": "select all items" }}
          disabled={mutationPending || data.length === 0}
        />
      ),
      render: (_, row) => (
        <FormControlLabel
          tw="-m-4 -ml-2"
          control={
            <Checkbox
              checked={selected.includes(row.id)}
              onClick={(e) => e.stopPropagation()}
              onChange={() => toggleSelection(row.id)}
            />
          }
          disabled={mutationPending || row.status !== "submitted"}
          label=""
          labelPlacement="bottom"
        />
      ),
    }),
    {
      id: "budgets",
      label: "Budget Status",
      render: (budgets) => <BudgetStatusChip budgets={budgets} />,
    },
    {
      id: "id",
      label: "Order-set #",
      sort: "id",
      render: (id, os) => (
        <div tw="flex gap-2 items-center">
          {readOnly ? (
            id
          ) : (
            <Link
              tw="font-medium underline tracking-wide"
              to={`/orders/open/${id}#${view}`}
            >
              {id}
            </Link>
          )}
          {os.orderSetVariants.some((osv) => osv.isRush) && (
            <Tooltip title="Has Item on Rush Status">
              <FaIcon icon={"truck-fast"} tw="text-blue-600" />
            </Tooltip>
          )}
          {os.status === "partial-approval-error" && (
            <Tooltip title="Partial Approval Error">
              <FaIcon icon={"exclamation-triangle"} tw="text-red-600" />
            </Tooltip>
          )}
        </div>
      ),
    },
    ...insertIf(view === "approvals", {
      id: "type",
      label: "Order Type",
      render: orderTypeLabel,
    }),
    {
      id: "user.name",
      label: "Ordered By",
      sort: "user-name",
      render: (name, row) => (
        <div tw="flex items-center">
          {isOverDue(row) && (
            <Tooltip
              placement="top-start"
              title="This order was not submitted on it's intended schedule, please notify your administrator if you are seeing this message."
            >
              <Warning fontSize="small" color="inherit" tw="pl-2" />
            </Tooltip>
          )}
          {name}
        </div>
      ),
    },
    {
      id: "territory.name",
      label: "Territory",
    },
    { id: "program.name", label: "Program", sort: "program-name" },
    ...insertIf(view === "rollup", {
      id: "anaplanPrograms",
      label: "Anaplan Program",
      render: getAnaplanProgramsLabel,
    }),
    {
      id: "_brands",
      label: "Brand",
      render: (_, row) => showFirst(getBrands(row)),
    },
    { id: "stateNames", label: "State" },
    {
      id: "totalEstimatedCost",
      label: "Est. Unit Costs",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(row.type === "from-inventory" ? 0 : v),
    },
    {
      id: "totalBeaconCost",
      label: "Total Beacon Cost",
      ...costColumn,
    },
    {
      id: "totalEstimatedFreight",
      label: "Est. Freight",
      ...costColumn,
    },
    {
      id: "totalEstimatesTaxes",
      label: "Est. Taxes",
      ...costColumn,
    },
    {
      id: "totalExtendedCost",
      label: "Est. Extended Total",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(
          row.type === "from-inventory" ? v - +row.totalEstimatedCost : v
        ),
    },
    {
      id: "submittedAt",
      label: "Order Submitted",
      sort: "submitted-at",
      render: displayDate,
    },
    ...insertIf<OrderSet>(
      view === "rollup",
      {
        id: "orderCalendarMonth.orderWindowCloseDate",
        label: "Order Window Close",
        render: formatDateString,
      },
      {
        id: "orderCalendarMonth.inMarketStartDate",
        label: "In Market Date",
        render: formatDateString,
      }
    ),
    { id: "status", label: "Status", render: displayOrderSetStatus },
    ...insertIf<OrderSet>(!readOnly && handleDeny, {
      id: "deny",
      label: "Deny",
      render: (_, os) => (
        <Tooltip title="Deny">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleDeny!(os.id);
            }}
            size="large"
          >
            <DoNotDisturbOn />
          </IconButton>
        </Tooltip>
      ),
    }),
  ];

  return (
    <SmartTable
      columns={columns}
      rows={data}
      {...tableProps}
      rowCss={(row) => isOverDue(row) && tw`bg-red-100 hover:bg-red-50`}
    />
  );
};

export default ApprovalsOrderSetTable;
