import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import {
  patchSuccess,
  setFailure,
  setIsLoading,
} from "src/redux/slices/patchOrderSlice";

import { BrokerFreight, PurchaseOrder } from "@models/PurchaseOrder";
import client from "@services/api";
import { BodyWithId } from "@utils/reactQuery";

import usePurchaseOrderId from "../usePurchaseOrderId";
import { itemRollupsKeyFactory } from "./itemRollupQueries";
import { purchaseOrdersKeyFactory } from "./purchaseOrderQueries";
import { purchaseOrderVariantsKeyFactory } from "./purchaseOrderVariantQueries";

type CreatePurchaseOrderPayload = {
  orderVariantIds: (number | string)[];
  programId: number | string;
  orderType: "on-demand" | "pre-order";
};

export function useCreatePurchaseOrderMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreatePurchaseOrderPayload) => {
      return client
        .post<PurchaseOrder>(`purchase-orders`, {
          __type: "purchase-order",
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: async (po) => {
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: itemRollupsKeyFactory.paginated._def,
      });
      await queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(po.id).queryKey,
        po
      );
    },
  });
}
type CreateFulfillmentPurchaseOrderPayload = {
  orderVariantIds: (number | string)[];
  programId: number;
  inMarketDate: string | Date;
};

export function useCreateFulfillmentPurchaseOrderMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      inMarketDate,
      ...data
    }: CreateFulfillmentPurchaseOrderPayload) => {
      return client
        .post<PurchaseOrder>(`convert-to-fulfillment-purchase-order`, {
          __type: "purchase-order",
          ...data,
          inMarketDate: format(new Date(inMarketDate), "yyyy-MM-dd"),
        })
        .then((res) => res.data);
    },
    onSuccess: async (po) => {
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: itemRollupsKeyFactory.paginated._def,
      });
      await queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(po.id).queryKey,
        po
      );
    },
  });
}

export function useUpdatePurchaseOrderMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: BodyWithId) => {
      dispatch(setIsLoading());
      return client
        .update<PurchaseOrder>(`purchase-orders/${id}`, {
          __type: "purchase-order",
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (entity) => {
      dispatch(patchSuccess());
      queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(entity.id).queryKey,
        entity
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}

export function useUpdateBrokerFreightMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();
  return useMutation({
    mutationFn: ({
      id,
      ...data
    }: {
      id: String;
      cost: number | string;
      invoiceNumber: string;
      invoiceDate: string;
    }) => {
      dispatch(setIsLoading());
      return client
        .update<BrokerFreight>(`broker-freight/${id}`, {
          __type: "broker-freight",
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (brokerFreight) => {
      dispatch(patchSuccess());
      queryClient.setQueryData<PurchaseOrder>(
        purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        (po) =>
          po && {
            ...po,
            brokerFreight: po.brokerFreight.map((bf) =>
              bf.id === brokerFreight.id ? brokerFreight : bf
            ),
          }
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}
export function useDeleteBrokerFreightMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();
  return useMutation({
    mutationFn: (id: string) => {
      dispatch(setIsLoading());
      return client.delete(`broker-freight/${id}`);
    },
    onSuccess: (_, id) => {
      dispatch(patchSuccess());
      queryClient.setQueryData<PurchaseOrder>(
        purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        (po) =>
          po && {
            ...po,
            brokerFreight: po.brokerFreight.filter((bf) => bf.id !== id),
          }
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}
