/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { CloseRounded, WarningRounded } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

import { Button, FaIcon } from "@features/ui";

import { setReportStatus } from "./globalReportSlice";
import { ReportStatus, TReportState } from "./types";

const extensionIcons = {
  csv: "file-csv",
  pdf: "file-pdf",
};

const ReportRow = ({
  filename,
  report,
  error,
  percentLoaded,
  onDownload,
}: {
  filename: string;
  report: TReportState;
  error?: any;
  percentLoaded: number;
  onDownload: () => Promise<void>;
}) => {
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const handleDownload = async () => {
    setDownloadLoading(true);
    await onDownload();
    setDownloadLoading(false);
  };

  const extension = filename.split(".").at(-1)!;

  return (
    <div css={report.status === ReportStatus.ABORTED && tw`opacity-50`}>
      <div tw="flex gap-4 py-3 px-6 items-start justify-between">
        <div tw="flex gap-2 items-start overflow-hidden">
          <FaIcon
            icon={extensionIcons[extension] ?? "file"}
            tw="text-primary-500 text-xl block mt-1 mx-1"
          />
          <span tw="text-neutral-600 leading-tight text-sm" title={filename}>
            {filename}
          </span>
        </div>
        <div tw="flex items-center">
          {report.status === ReportStatus.LOADING && (
            <>
              <div tw="bg-neutral-100 rounded-full py-1 px-3 flex items-center gap-2 text-sm font-medium text-neutral-600">
                <div>{Math.ceil(percentLoaded * 100)}%</div>
                <CircularProgress size={16} />
              </div>

              <Tooltip title="Cancel">
                <IconButton
                  size="small"
                  onClick={() =>
                    dispatch(
                      setReportStatus({
                        id: report.id,
                        status: ReportStatus.ABORTED,
                      })
                    )
                  }
                >
                  <CloseRounded tw="text-base" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(report.status === ReportStatus.COMPLETE ||
            report.status === ReportStatus.DOWNLOADED) && (
            <Button
              color={
                report.status === ReportStatus.COMPLETE
                  ? "primary"
                  : "secondary"
              }
              endIcon={
                <FaIcon
                  icon={
                    report.status === ReportStatus.DOWNLOADED
                      ? "check"
                      : "arrow-down-to-line"
                  }
                  variant="solid"
                />
              }
              tw="text-xs"
              onClick={handleDownload}
              loading={downloadLoading}
            >
              {report.status === ReportStatus.COMPLETE
                ? "Download"
                : "Downloaded"}
            </Button>
          )}
          {report.status === ReportStatus.FAILED && (
            <div tw="flex gap-1 items-center px-3">
              <WarningRounded tw="text-red-600 text-base" />
              <div tw="text-red-900 text-sm">ERROR</div>
            </div>
          )}
          {report.status === ReportStatus.ABORTED && (
            <div tw="px-3">
              <div tw="text-neutral-700 text-sm">ABORTED</div>
            </div>
          )}
          {report.status === ReportStatus.PENDING && (
            <div tw="px-3">
              <div tw="text-primary-700 text-sm">PENDING</div>
            </div>
          )}
        </div>
      </div>
      {report.status === ReportStatus.FAILED && (
        <div tw="bg-red-50 text-sm text-red-900 p-4 whitespace-pre-wrap">
          {error?.message || error}
        </div>
      )}
    </div>
  );
};

export default ReportRow;
