import React, { PropsWithChildren } from "react";

import { csvDataFromSchema, downloadAsCsv } from ".";
import { downloadAsCsvString } from "./downloadAsCsv";

type DataProps =
  | {
      data: Record<string, any>[];
      headers: { key: string; label: string }[];
    }
  | {
      data: any[][];
      headers?: never;
    }
  | {
      // Must be a valid CSV string
      data: string;
      headers?: never;
    };

type CSVLinkProps = PropsWithChildren &
  DataProps & {
    filename: string;
  };

// This component is a drop-in replacement for the react-csv library's CSVLink
const CSVLink = ({
  data,
  headers,
  filename,
  children,
  ...props
}: CSVLinkProps) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (typeof data === "string") {
      downloadAsCsvString(data, filename);
      return;
    }

    let csvData: any[][];
    if (headers) {
      const schema = headers.map((h) => ({ label: h.label, id: h.key }));
      csvData = csvDataFromSchema(schema, data);
    } else {
      csvData = data;
    }
    downloadAsCsv(csvData, filename);
  };
  return (
    <a
      download={filename}
      target="_self"
      href="#download"
      onClick={handleClick}
      {...props}
    >
      {children}
    </a>
  );
};

export default CSVLink;
