import React, { useEffect } from "react";
import { Navigate } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { AuthUserProvider } from "@features/auth";

// this page redirects to the auth0 auth page if the user is not authenticated
// AuthUserProvider handles the rest of the auth flow
const Login = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <AuthUserProvider>
      {/* Navigate to / when the user is authenticated */}
      <Navigate to="/" />
    </AuthUserProvider>
  );
};

export default Login;
