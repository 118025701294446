/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { Link } from "react-router";

import { Avatar } from "@mui/material";
import { IconButton } from "@mui/material";

import { useAuthUser } from "@features/auth/AuthUserProvider";

function stringToColor(string: string) {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + (hash << 3);
  }

  let h = hash % 360;
  let s = 50 + (hash % 10); // Saturation from 50% to 60%
  let b = 40 + (hash % 20); // Brightness from 40% to 60%

  return `hsl(${h}, ${s}%, ${b}%)`;
}

function stringAvatar(name: string) {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

const AvatarButton = React.forwardRef<HTMLAnchorElement>((props, ref) => {
  const user = useAuthUser();
  return (
    <IconButton ref={ref} tw="-my-1" component={Link} to="/profile" {...props}>
      <Avatar tw="w-10 h-10" {...stringAvatar(user.name)} alt={user.name} />
    </IconButton>
  );
});

export default AvatarButton;
