/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import { Tooltip } from "@mui/material";

import _, { filter } from "lodash";
import { formatUtcDate } from "src/utility/utilityFunctions";

import { Button, FaIcon } from "@features/ui";
import { Item, OrderSetVariant } from "@models";
import { OrderSetType } from "@models/OrderSet";

import useGetVariantAvailableInventory from "./hooks/useGetVariantAvailableInventory";

const statusTexts = {
  inactive: "Inactive",
  outOfStock: "Out of Stock",
  inCart: "In Cart",
  addToCart: "Add to cart",
};

type Status = keyof typeof statusTexts;

const getStatus = (
  item: Item,
  orderSetVariants: OrderSetVariant[],
  totalInventory?: false | number
): Status => {
  const orderSetVariantIds = orderSetVariants.map((osv) => osv.variant.id);

  if (filter(item.variants, "isActive").length === 0) return "inactive";

  const defaultVariant = item.variants?.find(
    (v) => v.selectedVariantOptions.length === 0
  );

  const allVariantsInCart =
    defaultVariant && defaultVariant.isActive
      ? orderSetVariantIds.includes(defaultVariant.id)
      : item.variants?.every(
          (v) =>
            !v.isActive ||
            v.selectedVariantOptions.length === 0 ||
            orderSetVariantIds.includes(v.id)
        );

  if (allVariantsInCart) {
    return "inCart";
  }
  if (typeof totalInventory === "number" && totalInventory <= 0) {
    return "outOfStock";
  }
  return "addToCart";
};

const AddToCartButton = ({
  item,
  orderSetVariants,
  addToOrderSet,
  orderType,
  loading: externalLoading,
}: {
  item: Item;
  orderSetVariants: OrderSetVariant[];
  addToOrderSet: (item: Item) => Promise<void>;
  orderType: OrderSetType;
  loading?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const getVariantQty = useGetVariantAvailableInventory();
  const totalInventory =
    orderType === "from-inventory" &&
    _.sumBy(item.variants, (v) => getVariantQty(v, { includeNational: true }));

  const [status, setStatus] = useState<Status>(
    getStatus(item, orderSetVariants)
  );

  const handleAddToCart = async () => {
    setLoading(true);
    await addToOrderSet(item);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    setStatus(getStatus(item, orderSetVariants, totalInventory));
  }, [loading, item, orderSetVariants, totalInventory]);

  return (
    <Button
      size="small"
      variant="contained"
      disableElevation
      disableRipple
      loading={externalLoading || loading}
      disabled={status !== "addToCart"}
      onClick={handleAddToCart}
      tw="whitespace-nowrap text-left"
      endIcon={
        // mr-1 makes the transition between the icon and the spinner smoother, avoids a layout shift
        status === "addToCart" ? (
          <FaIcon icon="plus" tw="text-base! mr-1" />
        ) : status === "outOfStock" && item.poInMarketDate ? (
          <Tooltip
            title={`Available ${formatUtcDate(item.poInMarketDate, "MMM d")}`}
          >
            <FaIcon
              icon="calendar-plus"
              variant="solid"
              tw="text-base! text-primary-500! pointer-events-auto bg-primary-50 px-1 rounded -mr-1"
            />
          </Tooltip>
        ) : undefined
      }
    >
      {statusTexts[status]}
    </Button>
  );
};

export default AddToCartButton;
