import _ from "lodash";
import { SUPPLIER_ROLES } from "src/constants/permissions";

import { displayDate } from "@features/reports";
import { ItemRollup } from "@models/ItemRollup";
import { UserRole } from "@models/UserRole";
import { CsvSchema } from "@utils/csv";

const purchaseOrderRollupSchema = (role: UserRole) => {
  let schema: CsvSchema<ItemRollup> = [
    { id: "variant.variantSku", label: "Sequence #" },
    { id: "supplierName", label: "Supplier" },
    { id: "userName", label: "Ordered By" },
    {
      id: "territoryNames",
      label: "Territory",
      f: (names) => names.join(", "),
    },
    { id: "orderProgramName", label: "Program" },
    { id: "brandNames", label: "Brand", f: (names) => names.join(", ") },
    { id: "externalWorkfrontId", label: "Project #" },
    { id: "itemType", label: "Item Type" },
    { id: "itemTypeDescription", label: "Item Desc." },
    { id: "totalOrdered", label: "Total Ordered" },
    { id: "qtyPendingCompliance", label: "Pending Compliance" },
    { id: "estimatedCost", label: "Est. Cost" },
    { id: "estimatedTotal", label: "Est. Total" },
    { id: "orderApprovedAt", label: "Order Approval Date", f: displayDate },
    { id: "inMarketDate", label: "In-Market Date", f: displayDate },
    { id: "standardSpecificationCode", label: "Spec. Code" },
  ];
  if (_.includes(SUPPLIER_ROLES, role)) {
    schema = schema.filter(
      (s) => !["supplierName", "estimatedCost"].includes(s.id)
    );
  }
  return schema;
};

export default purchaseOrderRollupSchema;
