/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useLocation } from "react-router";

import _ from "lodash";

import { useAuthUser } from "@features/auth";

import ControlledResourceFilterAutocomplete from "../components/ControlledResourceFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const BrandPopper = () => {
  const { channel } = useAuthUser();
  const { pathname } = useLocation();
  const { control } = useFilterContext();

  const onlyShowVisible =
    pathname.startsWith("/orders") || pathname === "/items";

  return (
    <ControlledResourceFilterAutocomplete
      skipsPagination
      name="brands"
      resource="brands"
      inputPlaceholder="Filter Brands"
      requestParams={{
        filter: {
          isActive: true,
          ...(onlyShowVisible && { hasVisibleItemsForChannel: channel }),
        },
      }}
      tapOptions={(opts) => _.sortBy(opts, "name")}
      control={control}
    />
  );
};

const BrandFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedBrands = filterValues.brands ?? [];
  return (
    <ResourceFilterChip
      resource="brands"
      ids={selectedBrands}
      prefix="Brand is"
      contentWhenEmpty="Select Brand"
      skipsPagination
      {...props}
    />
  );
};

const brandsFilter = {
  name: "brands",
  menuText: "Brands",
  Popper: BrandPopper,
  Chip: BrandFilterChip,
};

export default brandsFilter;
