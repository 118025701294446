import React from "react";
import { Link } from "react-router";

import { format } from "date-fns";

import { formatMoneyString } from "../../../utility/utilityFunctions";
import CollapsibleTable from "../../CollapsibleTable.js";
import QuoteDetails from "../Quotes/QuoteDetails.js";

const findLowestTierPrice = (tiers) =>
  tiers.reduce(
    (a, b) => {
      if (b.qty < a.qty) a = b;
      return a;
    },
    { qty: Infinity }
  )?.price;

const QuotesTable = ({ data, programId, classes }) => {
  return (
    <CollapsibleTable
      // cell is the only required value, anything else will be passed along
      // as a prop to the <th> element.
      headers={[
        { cell: "Sequence #" },
        { cell: "Item Type" },
        { cell: "Supplier" },
        { cell: "Lowest Tier Price", align: "right" },
        { cell: "Round", align: "right" },
        { cell: "Last Action Date", align: "right" },
      ]}
      rows={data.map((quote) => {
        const lowestTierPrice = findLowestTierPrice(quote.pricingTierQuotes);

        return {
          row: [
            // cells can be strings, numbers, or React elements
            // any value other than cell will be passed as a prop to the <td> element
            {
              cell: (
                <Link to={`/planning/rfqs/${programId}/item/${quote.itemId}`}>
                  {quote.itemNumber}
                </Link>
              ),
            },
            { cell: quote.itemType },
            { cell: quote.supplierName },
            {
              cell: lowestTierPrice ? (
                formatMoneyString(lowestTierPrice)
              ) : (
                <span style={{ color: "#AAA" }}>Not quoted</span>
              ),
              align: "right",
            },
            { cell: quote.round, align: "right" },
            {
              cell: format(new Date(quote.updatedAt), "MM/dd/yyyy"),
              align: "right",
            },
          ],
          // Content is a React element that will be hidden in the dropDown
          content:
            quote.pricingTierQuotes[0].price === null ? (
              false
            ) : (
              <QuoteDetails currentQuote={quote} classes={classes} />
            ),
        };
      })}
    />
  );
};

export default QuotesTable;
