/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useNavigate, useParams } from "react-router";

import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import { FaIcon } from "@features/ui";

import useOrderWindowPrograms from "./usePreOrderPrograms";

const programLabel = (program) =>
  `${program.brands.length === 1 ? program.brands[0].name : "Multi Brand"} - ${
    program.name
  }`;

const PreOrderProgramSelector = () => {
  const navigate = useNavigate();
  const { orderWindowId, programId } = useParams();
  const { data, orderSetQuery, programsQuery } = useOrderWindowPrograms(
    orderWindowId as string
  );

  const handleChange = (event) => {
    const programId = event.target.value;
    navigate(`/orders/${orderWindowId}/${programId}`, { replace: true });
    orderSetQuery.refetch();
  };

  if (programsQuery.isLoading)
    return <Skeleton variant="text" width={200} height={32} />;

  return (
    <>
      <FormControl
        tw="min-w-0 max-w-fit flex-shrink overflow-hidden"
        size="small"
      >
        <Select
          variant="outlined"
          name="programs"
          labelId="program-select"
          id="programs"
          tw="w-auto"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            style: { maxHeight: "75vh" },
          }}
          value={programId}
          onChange={handleChange}
          onFocus={(evt) => evt.stopPropagation()}
          onBlur={(evt) => evt.stopPropagation()}
          onClose={(evt) => evt.stopPropagation()}
          displayEmpty
          renderValue={(val) => {
            const line = val && data.find((p) => p.program.id === val);
            return (
              <Typography noWrap tw="font-medium text-lg">
                {line ? programLabel(line.program) : "Select Program"}
              </Typography>
            );
          }}
        >
          {data.map(({ program, orderSet }) => (
            <MenuItem value={program.id} key={program.id}>
              <div tw="flex gap-2 items-center">
                <Tooltip
                  placement="left"
                  enterDelay={500}
                  title={
                    !orderSet
                      ? "Not Started"
                      : orderSet.isWorkComplete
                      ? "Marked Complete for Review"
                      : "In Progress"
                  }
                >
                  <div tw="min-w-[30px] p-2">
                    {!orderSetQuery.isLoading && (
                      <>
                        {!orderSet && (
                          <FaIcon icon="circle" tw="text-neutral-300" />
                        )}
                        {orderSet && !orderSet.isWorkComplete && (
                          <FaIcon
                            icon="circle-half-stroke"
                            tw="text-primary-500"
                          />
                        )}
                        {orderSet?.isWorkComplete && (
                          <FaIcon
                            icon="circle-check"
                            variant="solid"
                            tw="text-green-500"
                          />
                        )}
                      </>
                    )}
                  </div>
                </Tooltip>
                <ListItemText
                  primary={programLabel(program)}
                  primaryTypographyProps={tw`text-base`}
                />
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default PreOrderProgramSelector;
