import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { Outlet, RouteObject, RouterProvider } from "react-router";

import FourOhFour from "src/pages/FourOhFour";

import { UserRole } from "@models/UserRole";
import { sentryCreateBrowserRouterV7 } from "@services/sentry";
import useRoleIs from "@utils/useRoleIs";

import routesRoot from "./routesRoot";
import { protectedRoute } from "./types";

// Recursively filters protectedRoutes based on the user's role
// If the user is not allowed to access a route, return a 404 page without children,
// otherwise, recursively protect child routes

interface ProtectedRouteProps {
  element: ReactNode | undefined;
  allowedRoles?: readonly UserRole[];
}

const ProtectedRoute = ({
  element,
  allowedRoles,
}: ProtectedRouteProps): ReactElement => {
  const roleIs = useRoleIs();
  const allowed = !allowedRoles || roleIs(allowedRoles);

  if (!allowed) return <FourOhFour />;

  return element ? <>{element}</> : <Outlet />;
};

const ProtectedRouterProvider = (props) => {
  const protectRoutes = useCallback(
    (routes: protectedRoute[]): RouteObject[] =>
      routes.map(
        (route) =>
          ({
            ...route,
            element: (
              <ProtectedRoute
                element={route.element}
                allowedRoles={route.allowedRoles as UserRole[]}
              />
            ),
            children:
              route.children &&
              protectRoutes(
                route.children.map((child) => ({
                  ...child,
                  allowedRoles:
                    child.allowedRoles ?? route.defaultChildrenAllowedRoles,
                }))
              ),
          }) as RouteObject
      ),
    []
  );

  const router = useMemo(() => {
    const protectedRoutes = protectRoutes(routesRoot);
    return sentryCreateBrowserRouterV7(protectedRoutes);
  }, [protectRoutes]);

  return <RouterProvider router={router} {...props} />;
};

export default ProtectedRouterProvider;
