/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useNavigate } from "react-router";

import { Contained, StyledButton } from "src/components/StyledComponents";
import ConfirmDenyModal from "src/components/Utility/Modals/ConfirmDenyModal";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { useMutateError } from "@features/errors";
import client from "@services/api";
import { useLastLocation } from "@services/reactRouterDom";

import { getExtendedCost } from "../helpers";
import { useDenyOrderSetMutation } from "../queries";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";

const OrderSetApproveDenyBar = ({ backUrl }) => {
  const setMutateError = useMutateError();
  const navigate = useNavigate();
  const lastLocation = useLastLocation();
  const denyMutation = useDenyOrderSetMutation();

  const [denyOpen, setDenyOpen] = useState(false);

  const { orderSet, orders } = useCurrentOrderSet();

  const orderSetExtendedTotal = orders.reduce(
    (total, order) => total + getExtendedCost(order, order.type),
    0
  );
  const handleDeny = (id: string, note: string) =>
    denyMutation
      .mutateAsync({ id, note })
      .then(() => navigate(lastLocation ?? backUrl))
      .catch(setMutateError);

  const handleApprove = () =>
    client
      .post(`order-sets/${orderSet.id}/approve`)
      .then(() => navigate(lastLocation ?? backUrl))
      .catch(setMutateError);
  return (
    <>
      {denyOpen && (
        <ConfirmDenyModal
          open
          handleClose={() => setDenyOpen(false)}
          handleDeny={handleDeny}
          orderSetId={orderSet.id}
        />
      )}
      <div tw="fixed inset-0 top-auto bottom-4 z-[2100]">
        <Contained>
          <div tw="rounded shadow-md bg-white flex justify-end items-center gap-3 px-4 py-3">
            <h2 tw="text-neutral-600 text-lg">
              Total:{" "}
              <b tw="font-medium">{formatMoneyString(orderSetExtendedTotal)}</b>
            </h2>
            {!orderSet.markedForApproval && (
              <>
                <StyledButton cta onClick={handleApprove}>
                  Approve
                </StyledButton>
                <StyledButton outlined onClick={() => setDenyOpen(true)}>
                  Deny
                </StyledButton>
              </>
            )}
            {orderSet.markedForApproval && <p>Approval Pending</p>}
          </div>
        </Contained>
      </div>
    </>
  );
};

export default OrderSetApproveDenyBar;
