import { AxiosError, isAxiosError } from "axios";
import _ from "lodash";

import { reportUnexpectedApiErrorMessage } from "@services/sentry/captureException";

export default function parseApiErrorMessage(error: Error | AxiosError) {
  if (isAxiosError(error)) {
    if (error.code === "ETIMEDOUT") {
      return "The request took too long. This is most likely due to a slow internet connection. Please check your connection and try again.";
    }
    if (typeof error.response?.data === "string") {
      return error.response.data;
    }

    if (
      Array.isArray(error.response?.data.errors) &&
      error.response?.data.errors[0].detail
    ) {
      return error.response.data.errors
        .map((e) => e.detail.replace(/^base /i, ""))
        .join("\n");
    }

    reportUnexpectedApiErrorMessage(error);
    return error.message;
  }
  return error.message;
}
