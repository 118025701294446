import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { AuthUserProvider } from "@features/auth";
import { GlobalConfirmProvider } from "@features/confirm";
import { ItemModal } from "@features/items";
import { FetchUserDraftOrderSets } from "@features/orders";
import { GlobalReportManager } from "@features/reports";
import { LastLocationProvider } from "@services/reactRouterDom";

import NavWrapper from "./components/Navigation/NavWrapper";
import ErrorBoundary from "./components/Utility/ErrorBoundary";
import ErrorModal from "./components/Utility/ErrorModal";
import Loading from "./components/Utility/Loading";
import StepperLoader from "./components/Utility/StepperLoader";
import {
  FREIGHT_FORWARDER,
  PLANNING_TOOL_SUPPLIER,
  READ_ONLY,
  SUPPLIER_ROLES,
} from "./constants/permissions";
import useRedirect from "./hooks/useRedirect";
import { fetchWarehouse } from "./redux/slices/addresses/addressSlice";
import { fetchBUs } from "./redux/slices/items/businessUnitSlice";
import { getProgramTypes } from "./redux/slices/programs/programTypeSlice";
import { fetchAllSuppliers } from "./redux/slices/suppliers/supplierSlice";
import {
  fetchStates,
  fetchTerritories,
} from "./redux/slices/territories/territorySlice";

const App = () => {
  const dispatch = useDispatch();

  useRedirect();

  const { role, status, supplierId, territories } = useSelector(
    (state) => state.user
  );

  const { isStepperLoad, isLoading } = useSelector((state) => state.globalLoad);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  useEffect(() => {
    if (role && status === "active") {
      if (!SUPPLIER_ROLES.includes(role) && role !== FREIGHT_FORWARDER) {
        dispatch(fetchAllSuppliers());
        dispatch(fetchTerritories());
        dispatch(fetchStates());
        dispatch(fetchBUs());
        dispatch(fetchWarehouse());
        dispatch(getProgramTypes());
      } else {
        if (SUPPLIER_ROLES.includes(role)) {
          if (role === PLANNING_TOOL_SUPPLIER) {
            dispatch(fetchAllSuppliers());
            dispatch(getProgramTypes());
          }
        }
      }
    }
  }, [dispatch, role, isAuthenticated, isAuthLoading, status]);
  return (
    <ErrorBoundary>
      {/* AuthUserProvider displays landing is user isn't logged in */}
      <LastLocationProvider>
        <AuthUserProvider>
          <FetchUserDraftOrderSets />
          <GlobalConfirmProvider>
            <ErrorModal />
            {role && (
              <NavWrapper>
                <GlobalReportManager />
                <ItemModal />
                {isStepperLoad && <StepperLoader />}
                {isLoading && <Loading partial />}
                {role === READ_ONLY && territories.length === 0 && (
                  <Navigate to="/new-user" />
                )}
                {status === "inactive" && <Navigate to="/deactivated-user" />}
                {SUPPLIER_ROLES.includes(role) && !supplierId && (
                  <Navigate to="/new-supplier" />
                )}
                <Outlet />
              </NavWrapper>
            )}
          </GlobalConfirmProvider>
        </AuthUserProvider>
      </LastLocationProvider>
    </ErrorBoundary>
  );
};

export default App;
