/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Error } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";

import _ from "lodash";
import { ControlledInputBase } from "src/components/Forms/ControlledInputs";
import { removeOrderVariantError } from "src/redux/slices/ordering/orderSetSlice";
import { intValidation } from "src/utility/inputHelpers";
import { roundUp } from "src/utility/utilityFunctions";

import {
  getPriorApprovalStatus,
  isOrderVariantCompliant,
} from "@features/compliance";
import { OrderVariant } from "@models/OrderVariant";

import OrderVariantBudget from "./OrderVariantBudget";
import OrderVariantCostBreakdown from "./OrderVariantCostBreakdown";
import useSetOrderVariantQtyMutation from "./data/mutations/useSetOrderVariantQty";

type OrderVariantError = {
  error: string;
  maxQuantity?: number;
};

const InputCell = ({
  inputRefs,
  orderVariant,
  handleKeyEvent,
}: {
  inputRefs: React.MutableRefObject<Record<string, HTMLInputElement | null>>;
  orderVariant: OrderVariant;
  handleKeyEvent: (evt: any) => void;
}) => {
  const dispatch = useDispatch();
  const orderVariantError: undefined | OrderVariantError = useSelector(
    (state: any) => state.orderSet.orderVariantErrors[orderVariant.id]
  );
  const { control, resetField } = useFormContext();
  const setOrderVariantQtyMutation = useSetOrderVariantQtyMutation();

  // compliance status should not change over the lifecycle of this component
  const complianceStatus = React.useMemo(
    () => {
      const isOrderCompliant = isOrderVariantCompliant(orderVariant);
      if (!isOrderCompliant) return "Not Compliant";

      const priorApprovalStatus =
        orderVariant.orderType === "from-inventory"
          ? getPriorApprovalStatus(orderVariant)
          : "Ok";

      return priorApprovalStatus;
    },
    // eslint-disable-next-line
    [orderVariant.id]
  );

  const isCompliant = complianceStatus === "Ok";
  const handleSaveRef = useRef<any>(null);

  handleSaveRef.current = (e) => {
    const newQty = roundUp(e.target.value, orderVariant.qtyPerPack);
    if (+newQty !== +orderVariant.qty) {
      setOrderVariantQtyMutation.mutate(
        {
          id: orderVariant.id,
          qty: +newQty,
        },
        {
          onSuccess: ({ orderVariant }) => {
            resetField(orderVariant.id, { defaultValue: orderVariant.qty });
          },
        }
      );
    } else {
      dispatch(removeOrderVariantError({ id: orderVariant.id }));
    }
  };

  const memoizedInput = React.useMemo(
    () =>
      isCompliant && (
        <ControlledInputBase
          ref={(el) => {
            inputRefs.current[orderVariant.id] = el;
          }}
          autoComplete="off"
          name={orderVariant.id}
          control={control}
          tw="absolute z-10 inset-0 px-3 pt-5 pb-2"
          defaultValue="0"
          onFocus={(e) => {
            e.target.select();
            if (e.target.getBoundingClientRect().left < 300) {
              // Scroll to the start of the table
              e.target.scrollIntoView({
                inline: "center",
              });
            }
            window.addEventListener("keydown", handleKeyEvent);
          }}
          onBlur={(e) => {
            window.removeEventListener("keydown", handleKeyEvent);
            handleSaveRef.current(e);
          }}
          rules={{
            ...intValidation,
            transformOnBlur: (v) =>
              !v || isNaN(+v) ? "0" : roundUp(v, orderVariant.qtyPerPack),
          }}
        />
      ),
    [
      isCompliant,
      orderVariant.id,
      handleKeyEvent,
      orderVariant.qtyPerPack,
      control,
      inputRefs,
    ]
  );

  if (!isCompliant) {
    return (
      <div
        tw="
        absolute z-10 inset-0 bg-neutral-200 text-neutral-500 text-base
        flex justify-center items-center"
      >
        {complianceStatus}
      </div>
    );
  }
  return (
    <>
      {memoizedInput}
      {/* Top text */}
      <OrderVariantBudget orderVariant={orderVariant} />
      {/* Right Text */}
      <div tw="text-sm absolute z-10 text-neutral-500 top-[18px] right-3 flex gap-2 items-center">
        {setOrderVariantQtyMutation.isPending && <CircularProgress size={16} />}
        {orderVariantError && (
          <Tooltip title={orderVariantError.error}>
            <div tw="flex gap-1 items-center rounded pt-px px-1 bg-red-50 border border-red-200">
              <Error tw="text-red-400 text-lg" />
              {orderVariantError.maxQuantity && (
                <span tw="text-red-400 font-medium">
                  max {orderVariantError.maxQuantity}
                </span>
              )}
            </div>
          </Tooltip>
        )}
        {orderVariant.qty > 0 && (
          <OrderVariantCostBreakdown orderVariant={orderVariant} />
        )}
      </div>
    </>
  );
};

export default React.memo(InputCell, (prev, next) => {
  return _.isEqual(prev.orderVariant, next.orderVariant);
});
