import { useSelector } from "react-redux";

import { useCurrentRegionalTerritorySubStates } from "@features/states";
import { Variant } from "@models/Variant";

export default function useGetVariantAvailableInventory() {
  const territory = useSelector((state: any) => state.app.territory);
  const { isRegional, subStateIds, isAllRegions } =
    useCurrentRegionalTerritorySubStates();

  return function getVariantAvailableInventory(
    variant: Variant,
    { includeNational }: { includeNational?: boolean } = {}
  ) {
    // Include national should be used to get the TOTAL units available to order for the current territory
    // without national is units ALLOCATED to the territory / sub-states of the territory
    if (!variant.isActive) return 0;

    const baseValue = includeNational ? variant.nationalAvailableToOrderQty : 0;

    return variant.variantAllocations.reduce((total, alloc) => {
      const isMatch = isRegional
        ? alloc.subState &&
          (isAllRegions || subStateIds.includes(alloc.subState.id))
        : alloc.territory?.id === territory;

      if (isMatch) {
        total += alloc.qty;
      }
      return total;
    }, baseValue);
  };
}
