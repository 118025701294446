/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterAddresses = makeResourceAutocompleteFilter({
  name: "addresses",
  resource: "addresses" as any,
  titleSingular: "Address",
  titlePlural: "Addresses",
  filterOptions: (opts) => opts,
  resourceFilterName: "search",
  requestParams: { sort: "name" },
});

export default filterAddresses;
