/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";

import _ from "lodash";
import { PLANNING_TOOL_SUPPLIER } from "src/constants/permissions";
import { useNoFetch } from "src/hooks/UtilityHooks";

import AssignWarehouseToItemModal from "@components/Purchasing/AssignWarehouseToItemModal";
import FulfillFromInventoryModal from "@components/Purchasing/FulfillFromInventoryModal";
import MultipleVariantModal from "@components/Purchasing/MultipleVariantModal";
import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import WarningModal from "@components/Utility/Modals/WarningModal";
import { useMutateError } from "@features/errors";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemRollupTable,
  useCreatePurchaseOrderMutation,
  usePaginatedItemRollupsQuery,
} from "@features/purchaseOrders";
import { useCreateRequestForPriceMutation } from "@features/purchaseOrders/queries/requestForPriceQueries";
import { RequestReportButton, useReport } from "@features/reports";
import { Button, FaIcon, OptionsMenu, PageTitle } from "@features/ui";
import { ItemRollup } from "@models/ItemRollup";
import client from "@services/api";
import { useDeepCompareEffect } from "@services/api/helperFunctions";
import { useSetLocation } from "@services/reactRouterDom";
import useHotKey from "@utils/useHotKey";
import useRoleIs from "@utils/useRoleIs";

import MarkAsTransferredModal from "./MarkAsTransferredModal";

const PurchaseOrderRollup = ({ view }: { view: "pre-order" | "on-demand" }) => {
  const setError = useMutateError();
  const setLocation = useSetLocation();
  const navigate = useNavigate();
  const isSupplier = useRoleIs()(PLANNING_TOOL_SUPPLIER);
  const [filters] = useFilterParams();
  const { channel } = useSelector((state: any) => state.app);
  const [selectedItemRollups, setSelectedItemRollups] = useState<ItemRollup[]>(
    []
  );

  const [warning, setWarning] = useState<null | string>(null);
  const [markAsTransferredModalOpen, setMarkAsTransferredModalOpen] =
    useState(false);
  const [multipleVariantModal, setMultipleVariantModal] = useState<null | {
    matchingRollupItems: ItemRollup[];
    onDemandRollupItems: ItemRollup[];
  }>(null);
  const [assignWarehouseItemId, setAssignWarehouseItemId] = useState<
    null | string
  >(null);
  const [variantCheckLoading, setVariantCheckLoading] = useState(false);
  const [inventoryFulfillmentModalOpen, setInventoryFulfillmentModalOpen] =
    useState(false);

  const createPurchaseOrderMutation = useCreatePurchaseOrderMutation();
  const createRFPMutation = useCreateRequestForPriceMutation();

  const mutationPending =
    variantCheckLoading ||
    createPurchaseOrderMutation.isPending ||
    createRFPMutation.isPending;
  // A path is an array of function to be called in order
  // Any of these functions may open a modal or require user input
  const paths = {
    createPO: [
      checkForVariantsOfSelectedItem,
      handleWarehouseAssignmentRequirement,
      handleCreatePurchaseOrder,
    ],
    fulfillWithInventory: [
      checkForVariantsOfSelectedItem,
      handleFulfillWithInventory,
    ],
    markAsTransfer: [checkForVariantsOfSelectedItem, handleMarkAsTransferred],
  };

  // Hold these values as refs to avoid re-rendering, or
  // having to wait for a re-render to access the values
  const pathRef = useRef<null | keyof typeof paths>(null);
  const stepRef = useRef(0);
  const selectedItemRollupsRef = useRef<ItemRollup[]>([]);

  const doStep = () =>
    pathRef.current && paths[pathRef.current][stepRef.current]();

  const startPath = (path) => {
    selectedItemRollupsRef.current = selectedItemRollups;

    // This shouldn't happen, but just in case
    if (selectedItemRollupsRef.current.length === 0) return;

    pathRef.current = path;
    stepRef.current = 0;

    doStep();
  };

  const finishPath = () => {
    pathRef.current = null;
    stepRef.current = 0;
    selectedItemRollupsRef.current = [];
  };

  const nextStep = () => {
    if (!pathRef.current) return;
    if (stepRef.current < paths[pathRef.current].length - 1) {
      stepRef.current++;
      doStep();
    } else {
      finishPath();
    }
  };

  const handleNewRFP = () => {
    const [ir] = selectedItemRollups;
    createRFPMutation.mutate(
      {
        itemId: ir.variant.item.id,
        programId: ir.orderProgram.id,
        orderVariantIds: ir.orderVariantIds,
      },
      {
        onSuccess: (rfp) => navigate(`/purchasing/rfp/${rfp.id}`),
        onError: (e) => setError(e, "Create Request For Price"),
      }
    );
  };

  async function checkForVariantsOfSelectedItem() {
    const itemNumber =
      selectedItemRollupsRef.current[0].itemNumber.split("-")[0];

    const programId = selectedItemRollupsRef.current[0].orderProgram.id;

    setVariantCheckLoading(true);

    const params = {
      skipPagination: true,
      filter: {
        isForRfp: false,
        itemNumber: itemNumber,
        channel: channel,
        orderSetType: view,
        programIds: [programId],
        ...(view === "pre-order" && {
          isAdHoc: false,
        }),
      },
    };

    let matchingRollupItems = await client
      .get(`item-rollups`, { params })
      .then((res) => res.data)
      .catch((err) => setError(err, "Purchase Order Rollup"));

    // We only want to show item-rollups that are not already selected
    matchingRollupItems = _.differenceBy(
      matchingRollupItems,
      selectedItemRollupsRef.current,
      "id"
    );

    // If the user is not filtering by on-demand, fetch on-demand rollup items
    // so we can offer the user to combine them into a single PO
    let onDemandRollupItems = [];
    if (view !== "on-demand") {
      const params = {
        skipPagination: true,
        filter: {
          isForRfp: false,
          itemNumber: itemNumber,
          channel: channel,
          orderSetType: "on-demand",
        },
      };
      onDemandRollupItems = await client
        .get(`item-rollups`, { params })
        .then((res) => res.data)
        .catch((err) => setError(err, "Purchase Order Rollup"));
    }

    setVariantCheckLoading(false);
    if (matchingRollupItems.length > 0 || onDemandRollupItems.length > 0) {
      setMultipleVariantModal({
        matchingRollupItems,
        onDemandRollupItems,
      });
    } else {
      nextStep();
    }
  }

  function handleWarehouseAssignmentRequirement() {
    const needsWarehouseAssignment = selectedItemRollupsRef.current.some(
      (ir) => ir.needsWarehouseAssignment
    );

    if (needsWarehouseAssignment) {
      setAssignWarehouseItemId(selectedItemRollupsRef.current[0].item.id);
    } else {
      nextStep();
    }
  }

  async function handleCreatePurchaseOrder() {
    const orderVariantIds = selectedItemRollupsRef.current.flatMap(
      (ir) => ir.orderVariantIds
    );

    const programId = selectedItemRollupsRef.current[0].orderProgram.id;

    createPurchaseOrderMutation.mutate(
      {
        orderVariantIds,
        orderType: view,
        programId: +programId,
      },
      {
        onError: (e) => setError(e, "Create Purchase Order"),
        onSuccess: (po) => navigate(`/purchasing/purchase-orders/${po.id}`),
      }
    );
  }

  function handleFulfillWithInventory() {
    const pendingCompliance = selectedItemRollupsRef.current.some(
      (ir) => ir.qtyPendingCompliance > 0
    );
    if (pendingCompliance) {
      setWarning(
        "There are compliance issues that need to be resolved on this item before you are able to fulfill with inventory."
      );
      finishPath();
    } else {
      setInventoryFulfillmentModalOpen(true);
    }
  }

  function handleMarkAsTransferred() {
    setMarkAsTransferredModalOpen(true);
  }

  const canFullfillWithInventory =
    _.sumBy(selectedItemRollups, "nationalAvailableToOrderQty") > 0;

  const firstSelectedItemId = selectedItemRollups[0]?.variant.item.id ?? null;

  const params = {
    filter: {
      isForRfp: false,
      itemNumber: filters.q,
      orderSetType: view,
      channel: channel,
      programIds: filters.programs,
      businessUnitNames: filters.businessUnits,
      supplierIds: filters.suppliers,
      brandIds: filters.brands,
      itemTypeIds: filters.itemTypes,
    },
    sort: filters.sort,
  };

  useReport("purchase-order-rollup", ["item-rollups", params]);
  const { data, ...tableProps } = usePaginatedItemRollupsQuery(params);

  useHotKey(
    ["cmd", "r"],
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (selectedItemRollups.length !== 1) return;
      handleNewRFP();
    },
    [selectedItemRollups]
  );

  let modifierKeyPrefix = "ctrl"; // control key
  if (
    navigator.platform.indexOf("Mac") === 0 ||
    navigator.platform === "iPhone"
  ) {
    modifierKeyPrefix = "⌘"; // command key
  }

  useNoFetch();

  useDeepCompareEffect(() => {
    setSelectedItemRollups([]);
  }, [params]);

  return (
    <>
      {assignWarehouseItemId && (
        <AssignWarehouseToItemModal
          itemId={assignWarehouseItemId}
          handleContinue={() => {
            nextStep();
            setAssignWarehouseItemId(null);
          }}
          handleCancel={() => {
            finishPath();
            setAssignWarehouseItemId(null);
          }}
        />
      )}
      {inventoryFulfillmentModalOpen && (
        <FulfillFromInventoryModal
          handleClose={() => setInventoryFulfillmentModalOpen(false)}
          itemRollups={selectedItemRollupsRef.current}
        />
      )}
      {warning && (
        <WarningModal
          open
          handleClose={() => setWarning(null)}
          message={warning}
        />
      )}
      {markAsTransferredModalOpen && (
        <MarkAsTransferredModal
          handleClose={() => {
            finishPath();
            setMarkAsTransferredModalOpen(false);
          }}
          itemRollups={selectedItemRollupsRef.current}
        />
      )}
      {multipleVariantModal && (
        <MultipleVariantModal
          {...multipleVariantModal}
          handleContinue={() => {
            nextStep();
            setMultipleVariantModal(null);
          }}
          handleCancel={() => {
            finishPath();
            setMultipleVariantModal(null);
          }}
          selectedItemRollupsRef={selectedItemRollupsRef}
          orderType={view}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap items-center justify-between gap-6">
          <div tw="flex gap-6 items-end">
            <PageTitle title="Purchase Order Rollup" tw="m-0" />
            <Tabs
              value={view}
              onChange={(_e, v) =>
                setLocation(
                  { pathname: `/purchasing/po-rollup/${v}` },
                  { replace: true }
                )
              }
              tw="min-h-0"
            >
              <Tab
                label="On-Demand"
                value={"on-demand"}
                tw="py-2 min-h-0 text-neutral-400 tracking-wider"
              />
              <Tab
                label="Pre-Order Window"
                value={"pre-order"}
                tw="py-2 min-h-0 text-neutral-400 tracking-wider"
              />
            </Tabs>
          </div>
          <div tw="flex flex-wrap gap-3">
            <div tw="flex">
              <Button
                tw="rounded-r-none"
                variant="contained"
                disabled={selectedItemRollups.length === 0 || mutationPending}
                onClick={() => startPath("createPO")}
                loading={createPurchaseOrderMutation.isPending}
              >
                Create Purchase Order
              </Button>
              {!isSupplier && (
                <OptionsMenu
                  title={"More Options"}
                  originRight
                  component={({ onClick }) => (
                    <Button
                      tw="block min-w-0 rounded-l-none h-full relative after:absolute after:inset-0 after:border-l-2 after:border-l-white/20"
                      variant="contained"
                      disabled={
                        selectedItemRollups.length === 0 || mutationPending
                      }
                      onClick={onClick}
                    >
                      <FaIcon
                        icon="caret-down"
                        variant="solid"
                        tw="text-base"
                      />
                    </Button>
                  )}
                >
                  <ListItemButton
                    tw="py-1"
                    disabled={selectedItemRollups.length !== 1}
                    onClick={() => handleNewRFP()}
                  >
                    <ListItemIcon tw="min-w-0 text-lg mr-6">
                      <FaIcon icon="tag" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Create RFP"
                      secondary={
                        <span tw="text-neutral-400">
                          {modifierKeyPrefix} + R
                        </span>
                      }
                    />
                  </ListItemButton>

                  <ListItemButton
                    tw="py-1"
                    onClick={() => startPath("markAsTransfer")}
                  >
                    <ListItemIcon tw="min-w-0 text-lg mr-6">
                      <FaIcon icon="check" />
                    </ListItemIcon>
                    <ListItemText primary="Mark as transferred" />
                  </ListItemButton>
                  <ListItemButton
                    tw="py-1"
                    disabled={!canFullfillWithInventory}
                    onClick={() => startPath("fulfillWithInventory")}
                  >
                    <ListItemIcon tw="min-w-0 text-lg mr-6">
                      <FaIcon icon="warehouse-full" />
                    </ListItemIcon>
                    <ListItemText primary="Use Inventory" />
                  </ListItemButton>
                </OptionsMenu>
              )}
            </div>

            <RequestReportButton reportName="purchase-order-rollup" />
          </div>
        </div>
        <TableCardContainer>
          <Filters
            searchTitle="Search Seq #"
            slots={[
              "brands",
              "programs",
              "itemTypes",
              ...(!isSupplier ? ["businessUnits", "suppliers"] : []),
            ]}
            alwaysShow={["brands", "suppliers"]}
            defaultValues={{ sort: "-order-approved-at" }}
          />
          <ItemRollupTable
            data={data}
            isRowSelected={(row) =>
              selectedItemRollups.some((r) => r.id === row.id)
            }
            onCheckRow={async (row) =>
              setSelectedItemRollups(_.xorBy(selectedItemRollups, [row], "id"))
            }
            isRowDisabled={(row) =>
              !!firstSelectedItemId && row.item.id !== firstSelectedItemId
            }
            view={"purchase-order"}
            {...tableProps}
          />
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PurchaseOrderRollup;
