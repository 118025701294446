/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { Link } from "react-router";

import { Checkbox, ListItemButton } from "@mui/material";

import { format } from "date-fns";
import { SmartTable, insertIf, showFirst } from "src/components/SmartTable";
import {
  Column,
  PaginatedResourceProps,
} from "src/components/SmartTable/SmartTable";
import {
  formatMoneyUnlimitedDecimals,
  utcDate,
} from "src/utility/utilityFunctions";

import ConfirmCancelRollupItem from "@components/Utility/Modals/ConfirmCancelRollupItem";
import ItemPreviewImageButton from "@features/items/components/ItemPreviewImageButton";
import { getVariantName } from "@features/orders";
import { OptionsMenu } from "@features/ui";
import { Item } from "@models/Item";
import { ItemRollup } from "@models/ItemRollup";
import { Variant } from "@models/Variant";

import EditItemSupplierModal from "./EditItemSupplierModal";

type ApprovalsOrderSetTableProps = PaginatedResourceProps & {
  data: ItemRollup[];
  view: "purchase-order" | "rfp";
  isRowSelected: (row) => boolean;
  isRowDisabled: (row) => boolean;
  onCheckRow: (row) => void;
};

const costColumn = {
  align: "right" as const,
  render: (value) => <span>{formatMoneyUnlimitedDecimals(value)}</span>,
};

const ItemRollupTable = ({
  data,
  view,
  isRowDisabled,
  isRowSelected,
  onCheckRow,
  ...tableProps
}: ApprovalsOrderSetTableProps) => {
  const [itemSupplierModal, setItemSupplierModal] = useState<null | Item>(null);
  const [cancelOrderVariantsModal, setCancelOrderVariantsModal] = useState<
    null | number[]
  >(null);

  const columns: Column<ItemRollup>[] = [
    {
      id: "checkbox",
      label: "",
      render: (_, row) => (
        <Checkbox
          tw="-m-4 -ml-2"
          checked={isRowSelected(row)}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onCheckRow(row)}
          disabled={isRowDisabled(row)}
        />
      ),
    },
    {
      id: "variant.item",
      label: "Preview",
      render: (item) => <ItemPreviewImageButton item={item} />,
    },
    {
      id: "variant",
      label: "Item",
      sort: "item-number",
      render: (variant: Variant) => (
        <div>
          <div tw="text-neutral-600 font-medium tracking-wide">
            {variant.variantSku}
          </div>

          {variant.item.comment}
          {variant.selectedVariantOptions.length > 0 && (
            <div tw="text-sm text-neutral-500">{getVariantName(variant)}</div>
          )}
        </div>
      ),
    },
    {
      id: "supplierName",
      label: "Supplier",
    },
    { id: "userName", label: "Ordered By" },
    {
      id: "territoryNames",
      label: "Territory",
      render: showFirst,
    },
    { id: "orderProgramName", label: "Program", sort: "order-program-name" },
    {
      id: "brandNames",
      label: "Brand",
      render: showFirst,
    },
    { id: "externalWorkfrontId", label: "Project ID" },
    {
      id: "itemTypeDescription",
      label: "Item Type",
      sort: "item-type-description",
    },
    { id: "totalOrdered", label: "Total Ordered" },
    { id: "variant.item.mostRecentMoq", label: "MOQ" },
    ...insertIf<ItemRollup>(view === "purchase-order", {
      id: "qtyPendingCompliance",
      label: "Pending Compliance",
      render: (qty: number, ir) =>
        qty === 0 ? (
          0
        ) : (
          <Link
            tw="underline"
            to={`/compliance/pending/${ir.orderVariantIds.join(",")}#${
              ir.itemNumber
            }`}
          >{`${qty} pending`}</Link>
        ),
    }),
    {
      id: "estimatedCost",
      label: "Est. Cost",
      ...costColumn,
    },
    {
      id: "estimatedTotal",
      label: "Est. Total",
      ...costColumn,
    },
    {
      id: "orderApprovedAt",
      label: "Approved At",
      sort: "order-approved-at",
      render: (date) => format(new Date(date), "MM/dd/yyyy"),
    },
    {
      id: "inMarketDate",
      label: "In Market Date",
      sort: "in-market-date",
      render: (date) => format(utcDate(date), "MM/dd/yyyy"),
    },
    {
      id: "_action",
      label: "",
      render: (__, row) => (
        <OptionsMenu title={"Options"}>
          <ListItemButton
            onClick={() => setItemSupplierModal(row.variant.item)}
          >
            Edit Item Supplier
          </ListItemButton>
          <ListItemButton
            onClick={() => setCancelOrderVariantsModal(row.orderVariantIds)}
          >
            Cancel Purchase Order Item
          </ListItemButton>
        </OptionsMenu>
      ),
    },
  ];

  return (
    <>
      {itemSupplierModal && (
        <EditItemSupplierModal
          item={itemSupplierModal}
          handleClose={() => setItemSupplierModal(null)}
        />
      )}
      {cancelOrderVariantsModal && (
        <ConfirmCancelRollupItem
          open
          handleClose={() => setCancelOrderVariantsModal(null)}
          itemIds={cancelOrderVariantsModal}
          type={view}
        />
      )}
      <SmartTable columns={columns} rows={data} {...tableProps} />
    </>
  );
};

export default ItemRollupTable;
