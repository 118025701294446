/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { Link } from "react-router";

import { Card } from "@mui/material";

import { IconName } from "@fortawesome/fontawesome-svg-core";

import FaIcon from "./FaIcon";
import { Type } from "./Type";

type Color = "gray" | "amber" | "teal" | "sky" | "purple" | "fuchsia";

const iconColorVariants = {
  gray: tw`bg-neutral-50 text-neutral-500 group-hover:bg-primary-100 group-hover:text-primary-500`,
  amber: tw`bg-amber-50 text-amber-500 group-hover:bg-amber-100`,
  teal: tw`text-teal-500 bg-teal-50 group-hover:bg-teal-100`,
  sky: tw`bg-sky-50 text-sky-500 group-hover:bg-sky-100`,
  purple: tw`text-purple-500 bg-purple-50 group-hover:bg-purple-100`,
  orange: tw`text-orange-500 bg-orange-50 group-hover:bg-orange-100`,
};

const titleColorVariants = {
  gray: tw`group-hover:text-primary-500`,
  amber: tw`group-hover:text-amber-800`,
  teal: tw`group-hover:text-teal-800`,
  sky: tw`group-hover:text-sky-800`,
  purple: tw`group-hover:text-purple-800`,
  orange: tw`group-hover:text-orange-800`,
};

const cardColorVariants = {
  gray: tw`hover:bg-primary-50 hover:border-primary-100 hover:shadow-none`,
  amber: tw`hover:bg-amber-50 border-amber-500`,
  teal: tw`border-teal-500 hover:bg-teal-50`,
  sky: tw`hover:bg-sky-50 border-sky-500`,
  purple: tw`border-purple-500 hover:bg-purple-50`,
  orange: tw`border-orange-500 hover:bg-orange-50`,
};

function Dashboard(props) {
  return (
    <div
      tw="grid grid-cols-1 gap-3 lg:gap-6 md:grid-cols-2 lg:grid-cols-3 p-4"
      {...props}
    />
  );
}

const DashCard = ({
  link,
  title,
  secondary,
  icon,
  color,
  onClick,
}: {
  link?: string;
  title: string;
  secondary: string;
  icon?: IconName;
  color: Color;
  onClick?: () => void;
}) => {
  const linkProps = link
    ? {
        component: Link,
        to: link,
        // if link is external, open in new tab
        ...(link.startsWith("http") && {
          target: "_blank",
          rel: "noopener noreferrer",
        }),
      }
    : {
        component: "button",
      };

  return (
    <Card
      className="group"
      tw="text-left flex leading-normal transition-colors bg-white p-4 lg:p-6 hover:shadow-md"
      css={cardColorVariants[color]}
      onClick={onClick}
      {...linkProps}
    >
      <div tw="flex flex-1 items-start gap-4">
        {icon && (
          <div
            tw="rounded-full w-16 h-16 flex-none flex items-center justify-center text-xl transition-colors -mt-1"
            css={iconColorVariants[color]}
          >
            <FaIcon icon={icon} />
          </div>
        )}
        <div>
          <Type.H2
            tw="mb-2 underline-offset-2 transition-colors group-hover:underline font-medium"
            css={titleColorVariants[color]}
          >
            {title}
          </Type.H2>
          <Type.P tw="text-neutral-500 group-hover:text-neutral-600">
            {secondary}
          </Type.P>
        </div>
      </div>
    </Card>
  );
};

Dashboard.Card = DashCard;

export default Dashboard;
