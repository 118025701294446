/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { CloseRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import CsvReportDownload from "./CsvReportDownload";
import JsonApiReportDownload from "./JsonApiReportDownload";
import { clearAllReports, setReportTrayOpen } from "./globalReportSlice";
import useReportDefinitions from "./reportDefinitions";
import { ReportSource, ReportStatus, TGlobalReports } from "./types";

const GlobalReportManager = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const reportDefinitions = useReportDefinitions();

  const { reportQueue, reportTrayOpen } = useSelector(
    (state: any) => state.globalReports as TGlobalReports
  );

  const canClearAll = !reportQueue.some(
    (report) => report.status === ReportStatus.LOADING
  );

  const handleClearAll = () => {
    queryClient.resetQueries({ queryKey: ["reports"] });
    dispatch(clearAllReports());
  };

  if (reportQueue.length === 0) return null;
  return (
    <div
      tw="
          w-full max-w-lg
          fixed right-3 bottom-0 z-[9999]
          drop-shadow-xl shadow-sm bg-white rounded-t-xl
          overflow-hidden"
    >
      <div tw="flex items-center justify-between px-6 py-1 border-b-2 bg-neutral-700 text-neutral-50">
        <span>Report downloads</span>
        <div>
          <IconButton
            size="small"
            edge="end"
            onClick={() => dispatch(setReportTrayOpen(!reportTrayOpen))}
          >
            <ExpandMoreRounded
              tw="text-white"
              css={!reportTrayOpen && tw`rotate-180`}
            />
          </IconButton>
          {canClearAll && (
            <IconButton size="small" edge="end" onClick={handleClearAll}>
              <CloseRounded tw="text-white" />
            </IconButton>
          )}
        </div>
      </div>
      <Collapse in={reportTrayOpen} tw="max-h-60 overflow-auto">
        <div tw="mb-2 divide-y-2 divide-neutral-100">
          {reportQueue.map((report) => {
            if (report.source === ReportSource.CSV) {
              return <CsvReportDownload key={report.id} report={report} />;
            }
            if (report.source === ReportSource.JSON_API) {
              return (
                <JsonApiReportDownload
                  key={report.id}
                  report={report}
                  reportDefinition={reportDefinitions[report.name]}
                />
              );
            }
            return null;
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default GlobalReportManager;
