/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  InputBase,
  LinearProgress,
  autocompleteClasses,
  styled,
} from "@mui/material";

import { keepPreviousData } from "@tanstack/react-query";
import { get, sortBy, uniqBy } from "lodash";

import { routeResourceTypes, useApiResource } from "@services/api";
import { ResourceAutocompleteProps } from "@utils/forms/ResourceAutocomplete";
import useDebouncedValue from "@utils/useDebouncedValue";

import { useFilterContext } from "../filterContext";
import useSelectedResources from "../useSelectedResources";

const PAGE_SIZE = 20;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Loading = () => <CircularProgress tw="-mt-2" size={20} />;

const StyledAutocompletePopper = styled("div")(() => ({
  [`&.${autocompleteClasses.popperDisablePortal}`]: tw`relative`,
  [`& .${autocompleteClasses.paper}`]: tw`m-0 text-sm shadow-none`,
  [`& .${autocompleteClasses.listbox}`]: {
    ...tw`p-0 bg-white`,
    [`& .${autocompleteClasses.option}`]: {
      ...tw`items-center min-h-0 p-0 border-t border-neutral-200`,
      '&[aria-selected="true"]': tw`bg-transparent!`,
      '&:hover, [aria-selected="true"]:hover, &.Mui-focused': tw`bg-neutral-100!`,
    },
  },
}));

const filterOptions = (opts, { inputValue }) =>
  opts.filter((o) => o.name.toLowerCase().includes(inputValue.toLowerCase()));

function PopperComponent(props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} style={{ width: "auto" }} />;
}

export type ControlledResourceFilterAutocompleteProps<
  TResourceName extends keyof routeResourceTypes,
> = {
  control: Control;
  name: string;
  inputPlaceholder?: string;
  skipsPagination?: boolean;
} & Pick<
  ResourceAutocompleteProps<
    TResourceName,
    routeResourceTypes[TResourceName],
    true,
    false
  >,
  | "resource"
  | "resourceFilterName"
  | "requestParams"
  | "getOptionText"
  | "tapOptions"
  | "groupBy"
  | "onChange"
  | "filterOptions"
>;

const ControlledResourceFilterAutocomplete = <
  TResourceName extends keyof routeResourceTypes,
>({
  control,
  name,
  inputPlaceholder,
  resourceFilterName = "name",
  resource,
  requestParams,
  tapOptions,
  getOptionText,
  skipsPagination,
  onChange,
  ...props
}: ControlledResourceFilterAutocompleteProps<TResourceName>) => {
  const { filterValues } = useFilterContext();

  const existingValues = get(filterValues, name) ?? ([] as string[]);

  const [searchString, setSearchString] = useState<string>("");
  const [areAllResourcesOnFirstPage, setAreAllResourcesOnFirstPage] =
    useState(!!skipsPagination);

  // no need to query the api if all resources are on the first page
  const shouldFilter = !!resourceFilterName && !areAllResourcesOnFirstPage;
  const debouncedSearchString = useDebouncedValue(searchString, 200);

  const { data, isLoading, isValidating } = useApiResource(resource, {
    params: {
      ...requestParams,
      filter: {
        ...(shouldFilter &&
          debouncedSearchString && {
            [resourceFilterName]: debouncedSearchString,
          }),
        ...requestParams?.filter,
      },
      ...(shouldFilter && { page: { size: PAGE_SIZE } }),
    },
    placeholderData: keepPreviousData,
    staleTime: 5 * 60_000,
    gcTime: skipsPagination ? Infinity : 5 * 60_000,
  });

  const { data: selectedResources } = useSelectedResources(
    resource,
    existingValues,
    areAllResourcesOnFirstPage
  );

  let options = uniqBy(
    [...(data ?? []), ...selectedResources],
    "id"
  ) as routeResourceTypes[TResourceName][];
  if (tapOptions) {
    options = tapOptions(options);
  }
  options = getOptionText
    ? options.map((opt) => ({ ...opt, name: getOptionText(opt) }))
    : options;

  const sortedOptions = sortBy(options, (o) => !existingValues.includes(o.id));

  const getOpObj = (option) =>
    option?.id ? option : options.find((o) => o.id === option);

  useEffect(() => {
    if (
      !areAllResourcesOnFirstPage &&
      !debouncedSearchString &&
      data &&
      // if the data is less than the page size, all resources are on the first page
      // if the data is more than the page size, the endpoint doesn't use pagination
      data.length !== PAGE_SIZE
    ) {
      setAreAllResourcesOnFirstPage(true);
    }
  }, [data, debouncedSearchString, areAllResourcesOnFirstPage]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div tw="flex-col">
          <Autocomplete
            {...field}
            open
            multiple
            value={field.value || []}
            inputValue={searchString}
            onInputChange={(_, value, reason) =>
              reason !== "reset" && setSearchString(value)
            }
            onChange={(event: any, newValue: any[], reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              field.onChange(newValue.map((v) => v.id ?? v));
              onChange?.(event, newValue, reason);
            }}
            disableCloseOnSelect
            renderTags={() => null}
            noOptionsText={isLoading ? <Loading /> : "No options"}
            options={sortedOptions}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) =>
              option.id === getOpObj(value)?.id
            }
            getOptionLabel={(opt) => getOpObj(opt)?.name}
            renderOption={(props: any, option: any, { selected }) => (
              <li {...props} tw="py-0! px-0!" key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                <span tw="whitespace-nowrap pr-3">{option.name}</span>
              </li>
            )}
            renderInput={({ InputProps, InputLabelProps: _, ...params }) => {
              return (
                <>
                  <InputBase
                    {...params}
                    tw="p-3"
                    autoFocus
                    ref={InputProps.ref}
                    placeholder={inputPlaceholder}
                  />
                  <div tw="h-0.5 overflow-hidden">
                    {(isValidating || isLoading) && <LinearProgress />}
                  </div>
                </>
              );
            }}
            PopperComponent={PopperComponent}
            {...props}
          />
        </div>
      )}
    />
  );
};

export default ControlledResourceFilterAutocomplete;
