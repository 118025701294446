import React, { useState } from "react";

import {
  DefaultError,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import _ from "lodash";

import handleSessionExpired from "@features/auth/handleSessionExpired";
import { parseApiErrorMessage } from "@services/api";
import {
  captureMutationException,
  captureQueryException,
} from "@services/sentry";

const shouldIgnoreError = (error: DefaultError | undefined) => {
  // on Firefox, requests are aborted on page reload or navigation to other site, causing all on-going requests to throw an error
  if (!error || error.code === "ECONNABORTED") return true;
  return false;
};

const QueryClientProviderWrapper = (props: { children: React.ReactNode }) => {
  // make client persistent
  const [client] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (error, query) => {
          if (shouldIgnoreError(error)) return;
          // Catch 401, invalid_token errors and set to expire
          if (error.response?.status === 401) {
            handleSessionExpired();
            return;
          }
          captureQueryException(error, query);
        },
      }),
      mutationCache: new MutationCache({
        onError: (error, _variables, _context, mutation) => {
          if (shouldIgnoreError(error)) return;
          // Catch 401, invalid_token errors and set to expire
          if (error.response?.status === 401) {
            handleSessionExpired();
            return;
          }
          if (
            parseApiErrorMessage(error).includes("exceeds available inventory")
          ) {
            // Variant exceeds available inventory error
            return;
          }
          captureMutationException(error, mutation);
        },
      }),
      defaultOptions: {
        queries: {
          // because most of our data contains circular data, we can't use this optimization
          structuralSharing: false,
          retry: false,
        },
        mutations: {},
      },
    })
  );
  return <QueryClientProvider client={client} {...props} />;
};

export default QueryClientProviderWrapper;
