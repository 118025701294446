import { format } from "date-fns";
import _ from "lodash";
import fp from "lodash/fp";
import isEqual from "lodash/isEqual";

import { axiosGet } from "../../../api/axiosCalls";
import { mapItem } from "../../../redux/slices/planningTool/itemProgramsHelpers";
import { defaultFormValues } from "../../../utility/inputHelpers";

const today = format(new Date(), "yyyy-MM-dd");

/* 
Specs:

Back 4-Color
Back Finish
Dieline
Embossing
Finishing Type
Flat Size
Front 4-Color
Front Finish
Hot Stamp
Pack Out
Perf
Reference Number
Score
Stock
Supplier Instructions

Website Description:

Item Name
MOQ
Estimated Cost Based Off QTY
Standard Lead Time
Materials
Overall Size
Bottle Size
Bottle Capacity
Graphics Included
Inventory Item
Intended Usage/Channel
Special Instructions/Description


 */

const defaultFields = {
  artReferenceNumber: "",
  beaconCost: "0.00",
  becomesOnDemand: false,
  brands: [],
  comment: "",
  complexityScore: "",
  creativeInstructions: "",
  designType: "",
  estimatedCost: "0.00",
  includeBeacon: false,
  isAccolade: false,
  isActive: true,
  isInventory: false,
  isMetalOrWood: false,
  isSweepstakes: false,
  itemSubTypeId: "",
  itemTypeId: "",
  moq: "",
  orderableEndDate: null,
  orderableStartDate: today,
  orderCalendarMonthId: "",
  orderType: "",
  otherNotes: "",
  poInMarketDate: "",
  qtyPerPack: "",
  removeFromCatalogDate: null,
  rtaDeployment: true,
  sendToContentHub: false,

  isCoupon: false,
  couponBarcodeId: "",
  couponCopyDone: false,
  couponEstimatedRedemption: 0,
  couponPurchaseRequirementCode: "",
  couponExpirationDate: null,
  couponFaceValue: "",
  couponIssueDate: today,
  couponOfferDescription: "",
  couponTotalValueMir: "",
  couponUuid: "",

  isDigitalCoupon: false,

  couponOrderStartDate: null,
  couponOrderEndDate: null,

  couponTypeId: "",
  supplierId: "",

  clones: [],
  clonedFromId: "",
};

const itemFields = [
  "artReferenceNumber",
  "beaconCost",
  "becomesOnDemand",
  "brands",
  "comment",
  "complexityScore",
  "creativeInstructions",
  "designType",
  "estimatedCost",
  "includeBeacon",
  "isAccolade",
  "isActive",
  "isInventory",
  "isSweepstakes",
  "isMetalOrWood",
  "itemSubTypeId",
  "itemTypeId",
  "moq",
  "orderableEndDate",
  "orderableStartDate",
  "orderCalendarMonthId",
  "orderType",
  "otherNotes",
  "poInMarketDate",
  "qtyPerPack",
  "removeFromCatalogDate",
  "rtaDeployment",
  "sendToContentHub",
  "supplierId",
  "tieredPricingDescription",
];
const couponFields = [
  "artReferenceNumber",
  "brands",
  "comment",
  "complexityScore",
  "creativeInstructions",
  "designType",
  "estimatedCost",
  "estimatedQty",
  "isActive",
  "isSweepstakes",
  "itemSubTypeId",
  "itemTypeId",
  "moq",
  "orderCalendarMonthId",
  "orderType",
  "otherNotes",
  "qtyPerPack",
  "removeFromCatalogDate",
  "rtaDeployment",
  "sendToContentHub",

  "isCoupon",
  "couponBarcodeId",
  "couponCopyDone",
  "couponEstimatedRedemption",
  "couponPurchaseRequirementCode",
  "couponExpirationDate",
  "couponFaceValue",
  "couponIssueDate",
  "couponOfferDescription",
  "couponTotalValueMir",
  "couponUuid",

  "isDigitalCoupon",

  "couponOrderStartDate",
  "couponOrderEndDate",

  "couponTypeId",
  "supplierId",

  "clonedFromItemProgramId",
];

export const couponClonableFields = [
  "brands",
  "couponBarcodeId",
  "couponCopyDone",
  "couponPurchaseRequirementCode",
  "couponFaceValue",
  "couponOfferDescription",
  "couponTotalValueMir",
  "couponUuid",

  "couponOrderStartDate",
  "couponOrderEndDate",
  "couponIssueDate",
  "couponExpirationDate",

  "couponTypeId",
  "supplierId",
];

export const rerunDisabledFields = [
  "creativeInstructions",
  "designType",
  "itemOrderType",
  "itemSubTypeId",
  "itemTypeId",
  "otherNotes",
  "poInMarketDate",
  "qtyPerPack",
  "variantOptionsIds",
];

export const fieldsShouldNotDuplicate = ["designType"];

export const fields = {
  default: {
    required: [
      "brands",
      "itemTypeId",
      "orderCalendarMonthId",
      "couponEstimatedRedemption",
    ],
    disabled: [],
  },
  submitted: {
    required: [
      "brands",
      "itemTypeId",
      "itemSubTypeId",
      "orderCalendarMonthId",
      "designType",
      "removeFromCatalogDate",
      "estimatedCost",
      "orderableStartDate",

      "couponExpirationDate",
      "couponFaceValue",
      "couponOfferDescription",
      "couponTypeId",
      "couponOrderStartDate",
      "couponOrderEndDate",
      "couponEstimatedRedemption",
    ],
    disabled: ["designType"],
  },
  templatedCoupon: {
    disabled: [
      "couponBarcodeId",
      "couponCopyDone",
      "couponPurchaseRequirementCode",
      "couponFaceValue",
      "couponOfferDescription",
      "couponTotalValueMir",
      // "couponEstimatedRedemption", // 8/10/2023 cd says this should be editable
      "couponUuid",

      "couponOrderStartDate",
      "couponIssueDate",
      "couponExpirationDate",

      "couponTypeId",
      "supplierId",
    ],
    hidden: [
      "comment",
      "complexityScore",
      "creativeInstructions",
      "otherNotes",
    ],
  },
};

const mapVariantOptions = (ops) =>
  ops.map((op) => ({
    id: op.id,
    abbreviation: op.abbreviation,
    name: op.name,
    variantCategoryName: op["variant-category"].name,
  }));

export const fetchVariantOptions = async () => {
  try {
    const res = await axiosGet("/api/variant-options");
    if (res.error) throw res.error;
    return mapVariantOptions(res.data);
  } catch (error) {
    console.error(error);
  }
};

export const decoupleOrderType = (_data, programOrderType) => {
  let data = { ..._data };
  if (data.orderCalendarMonthId === "on-demand") {
    data.orderCalendarMonthId = null;
    data.orderType = "on-demand";
  } else {
    data.orderType = programOrderType;
  }
  return data;
};

export const coupleOrderType = (data) => {
  return {
    ...data,
    ...(data.orderType === "on-demand" && {
      orderCalendarMonthId: "on-demand",
    }),
  };
};

export const compareWithOrderType = (programOrderType) => (form, saved) => {
  const f = (obj) => {
    let data = decoupleOrderType(obj, programOrderType);
    // only compare brand ids
    data.brands = _(data.brands).map("id").sort().value();
    return data;
  };
  return isEqual(f(form), f(saved));
};
// const inspect = (pr) => (obj) => console.log(pr || "", obj) || obj;

export const formDataFromItem = fp.flow(
  fp.defaults(defaultFields),
  fp.pick(itemFields),
  (obj) => ({
    ...obj,
    estimatedCost: obj.estimatedCost
      ? Number(obj.estimatedCost).toFixed(2)
      : "",
    beaconCost: Number(obj.beaconCost).toFixed(2),
  }),
  coupleOrderType,
  defaultFormValues
);

export const couponDataFromForm = (data, programOrderType) => ({
  ...decoupleOrderType(data, programOrderType),
  couponEstimatedRedemption: data.couponEstimatedRedemption / 100,
});

export const formDataFromCoupon = fp.flow(
  fp.defaults(defaultFields),
  fp.pick(couponFields),
  (obj) => ({
    ...obj,
    isCoupon: true,
    estimatedCost: obj.estimatedCost
      ? Number(obj.estimatedCost).toFixed(2)
      : "",
    couponFaceValue: obj.couponFaceValue ? +obj.couponFaceValue : "",
    couponEstimatedRedemption:
      parseFloat(obj.couponEstimatedRedemption ?? 0) * 100,
    // coupons use their own orderable date fields
    // nullify the item default dates
    orderableStartDate: null,
    orderableEndDate: null,
  }),
  coupleOrderType,
  defaultFormValues
);

export const fetchItem = (itemNumber) =>
  axiosGet(`/api/items/${itemNumber}`).then((res) => mapItem(res.data));

export const vendorOptions = [
  "BYBE",
  "Dealamina",
  "Koupon Media",
  "MyRebateNow",
  "Other",
  "Quotient",
].map((v) => ({ id: v, label: v }));

const validFields = fp.flow(
  fp.pick([
    "sendToContentHub",
    "rtaDeployment",
    "qtyPerPack",
    "estimatedCost",
    "complexityScore",
    "moq",
  ]),
  fp.pickBy((v) => v !== null)
);

export const applyDefaultsFromItemType = (formData, itemTypes) => {
  const itemType = itemTypes.find(({ id }) => id === formData.itemTypeId);
  const subType =
    itemType.itemSubTypes.find(({ label }) => label === "Default") ||
    itemType.itemSubTypes[0];

  return {
    ...formData,
    itemSubTypeId: subType?.id || "",
    becomesOnDemand: formData.orderType === "on-demand",
    ...validFields(itemType),
    ...validFields(subType),
  };
};
