import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://7de2e54c16737037c3a901cc635c0a03@o555075.ingest.us.sentry.io/4508938338172928",
  environment: process.env.REACT_APP_DEV_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.extraErrorDataIntegration({
      depth: 5,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  normalizeDepth: 5,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\/api/],
  sendDefaultPii: true,
  enabled: process.env.REACT_APP_DEV_ENV !== "dev",
});
export const sentryCreateBrowserRouterV7 =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
