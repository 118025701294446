import {
  NON_SUPPLIER_ROLES,
  PURCHASERS_AND_SUPPLIERS,
  PURCHASER_ADMIN,
  READ_ONLY,
} from "src/constants/permissions";
import ReportAvailableInventory from "src/pages/ReportAvailableInventory";
import ReportBulkPOShipParams from "src/pages/ReportBulkPOShipParams";
import ReportCDC from "src/pages/ReportCDC";
import ReportComplianceAudit from "src/pages/ReportComplianceAudit";
import ReportCoupon from "src/pages/ReportCoupon";
import ReportCouponProduction from "src/pages/ReportCouponProduction";
import ReportFinanceJournal from "src/pages/ReportFinanceJournal";
import ReportInventory from "src/pages/ReportInventory";
import ReportInvoiceDoubleCheck from "src/pages/ReportInvoiceDoubleCheck";
import ReportInvoicing from "src/pages/ReportInvoicing";
import ReportOpenPurchaseOrders from "src/pages/ReportOpenPurchaseOrders";
import ReportOrderDetail from "src/pages/ReportOrderDetail";
import ReportOrderWindowDetail from "src/pages/ReportOrderWindowDetail";
import ReportOrderWindowItems from "src/pages/ReportOrderWindowItems";
import ReportOrderWindowSummary from "src/pages/ReportOrderWindowSummary";
import ReportOrderedItems from "src/pages/ReportOrderedItems";
import ReportPOAccrual from "src/pages/ReportPOAccrual";
import ReportPOCompliance from "src/pages/ReportPOCompliance";
import ReportPODeliveryDate from "src/pages/ReportPODeliveryDate";
import ReportPreOrderMOQAnalysis from "src/pages/ReportPreOrderMOQAnalysis";
import ReportPreOrderNonParticipant from "src/pages/ReportPreOrderNonParticipant";
import ReportProductList from "src/pages/ReportProductList";
import ReportPurchaseOrders from "src/pages/ReportPurchaseOrders";
import ReportQn from "src/pages/ReportQN";
import ReportRFPSummary from "src/pages/ReportRFPSummary";
import ReportRapid from "src/pages/ReportRapid";
import ReportRoadmapForecast from "src/pages/ReportRoadmapForecast";
import ReportShelfInventory from "src/pages/ReportShelfInventory";
import ReportSingleItemInventory from "src/pages/ReportSingleItemInventory";
import ReportSupplierSpend from "src/pages/ReportSupplierSpend";
import ReportsLanding from "src/pages/ReportsLanding";
import SuspenseRoute from "src/routes/SuspenseRoute";

import { protectedRoute } from "./types";

const FINANCE_ROLES = [
  "purchaser",
  "select-purchaser",
  "super",
  "field2",
  READ_ONLY,
  "compliance",
];

const ro: protectedRoute = {
  path: "reports",
  defaultChildrenAllowedRoles: NON_SUPPLIER_ROLES,
  children: [
    { path: "", element: <ReportsLanding /> },
    {
      path: "must-haves",
      allowedRoles: PURCHASER_ADMIN,
      element: (
        <SuspenseRoute
          componentImport={() => import("src/pages/ReportMustHaves")}
        />
      ),
    },
    {
      path: "po-ship-list",
      allowedRoles: PURCHASERS_AND_SUPPLIERS,
      element: <ReportBulkPOShipParams />,
    },
    {
      path: "cdc",
      allowedRoles: ["select-purchaser", "super"],
      element: <ReportCDC />,
    },
    {
      path: "roadmap-forecast",
      element: <ReportRoadmapForecast />,
    },
    {
      path: "custom-coupon",
      allowedRoles: FINANCE_ROLES,
      element: <ReportCoupon />,
    },
    {
      path: "coupon-production",
      allowedRoles: FINANCE_ROLES,
      element: <ReportCouponProduction />,
    },
    {
      path: "finance-journal",
      allowedRoles: FINANCE_ROLES,
      element: <ReportFinanceJournal />,
    },
    {
      path: "order-window-items",
      allowedRoles: FINANCE_ROLES,
      element: <ReportOrderWindowItems />,
    },
    {
      path: "pre-order-non-participant",
      allowedRoles: FINANCE_ROLES,
      element: <ReportPreOrderNonParticipant />,
    },
    {
      path: "order-window-detail",
      allowedRoles: FINANCE_ROLES,
      element: <ReportOrderWindowDetail />,
    },
    {
      path: "open-purchase-orders",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportOpenPurchaseOrders />,
    },
    {
      path: "order-detail",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportOrderDetail />,
    },
    {
      path: "ordered-items",
      allowedRoles: ["super"],
      element: <ReportOrderedItems />,
    },
    {
      path: "order-window-summary-presentation",
      element: (
        <SuspenseRoute
          componentImport={() =>
            import("src/pages/ReportOrderWindowSummaryPres")
          }
        />
      ),
    },
    {
      path: "order-window-summary",
      allowedRoles: FINANCE_ROLES,
      element: <ReportOrderWindowSummary />,
    },
    {
      path: "rfp-summary",
      allowedRoles: FINANCE_ROLES,
      element: <ReportRFPSummary />,
    },
    {
      path: "items",
      element: (
        <SuspenseRoute
          componentImport={() => import("src/pages/ReportItems")}
        />
      ),
    },
    {
      path: "inventory",
      allowedRoles: ["field2", ...PURCHASER_ADMIN],
      element: <ReportInventory />,
    },
    {
      path: "available-inventory",
      allowedRoles: NON_SUPPLIER_ROLES,
      element: <ReportAvailableInventory />,
    },
    {
      path: "invoice-double-check",
      allowedRoles: ["select-purchaser", "super"],
      element: <ReportInvoiceDoubleCheck />,
    },
    {
      path: "invoicing",
      allowedRoles: ["select-purchaser", "super"],
      element: <ReportInvoicing />,
    },
    {
      path: "po-compliance",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportPOCompliance />,
    },
    {
      path: "compliance-audit",
      allowedRoles: [...PURCHASER_ADMIN, "compliance"],
      element: <ReportComplianceAudit />,
    },
    {
      path: "qn-invoice",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportQn />,
    },
    {
      path: "purchase-order-report",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportPurchaseOrders />,
    },
    {
      path: "variant-allocation",
      allowedRoles: ["field1", "field2", ...PURCHASER_ADMIN],
      element: <ReportShelfInventory />,
    },
    {
      path: "po-delivery-date-report",
      allowedRoles: ["purchaser", "select-purchaser", "super"],
      element: <ReportPODeliveryDate />,
    },
    {
      path: "single-item-inventory",
      allowedRoles: ["field2", ...PURCHASER_ADMIN],
      element: <ReportSingleItemInventory />,
    },
    {
      path: "supplier-spend",
      allowedRoles: ["super"],
      element: <ReportSupplierSpend />,
    },
    {
      path: "pre-order-moq-analysis",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportPreOrderMOQAnalysis />,
    },
    {
      path: "order-window-moq",
      allowedRoles: ["super", "purchaser", "select-purchaser", "field2"],
      element: (
        <SuspenseRoute
          componentImport={() => import("src/pages/ReportOrderWindowMOQ")}
        />
      ),
    },
    {
      path: "order-window-spend",
      allowedRoles: PURCHASER_ADMIN,
      element: (
        <SuspenseRoute
          componentImport={() => import("src/pages/ReportOrderWindowSpend")}
        />
      ),
    },
    {
      path: "brand-recap",
      allowedRoles: ["super"],
      element: (
        <SuspenseRoute
          componentImport={() => import("../pages/ReportBrandRecap")}
        />
      ),
    },
    {
      path: "po-accrual",
      allowedRoles: PURCHASER_ADMIN,
      element: <ReportPOAccrual />,
    },
    {
      path: "product-list",
      allowedRoles: ["super"],
      element: <ReportProductList />,
    },
    {
      path: "product-list-pdf",
      allowedRoles: ["super"],
      element: (
        <SuspenseRoute
          componentImport={() => import("src/pages/ReportProductListPDF")}
        />
      ),
    },
    {
      path: "rapid-report",
      allowedRoles: ["super", "purchaser"],
      element: <ReportRapid />,
    },
  ],
};

export default ro;
