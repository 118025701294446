/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router";

import { Edit } from "@mui/icons-material";
import { Card, LinearProgress, Skeleton, Typography } from "@mui/material";

import _ from "lodash";
import { StyledButton } from "src/components/StyledComponents";
import { PLANNING_TOOL_SUPPLIER } from "src/constants/permissions";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { useMutateError } from "@features/errors";
import { Button, FaIcon } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

import { DisplayBillable } from "../BillableCosts";
import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";
import useDeleteOrderSetMutation from "../orderSet/data/mutations/useDeleteOrderSetMutation";
import useSubmitOrderSetMutation from "../orderSet/data/mutations/useSubmitOrderSetMutation";
import useUpdateOrderSetMutation from "../orderSet/data/mutations/useUpdateOrderSetMutation";
import SetIsWorkInProgressButton from "../preOrder/SetIsWorkInProgressButton";
import OrderSetWrapupButton from "./OrderSetWrapupButton";

const OrderSetSummaryActionPanel = () => {
  const navigate = useNavigate();
  const roleIs = useRoleIs();
  const setMutateError = useMutateError();
  const deleteOrderSet = useDeleteOrderSetMutation();
  const submitOrderSet = useSubmitOrderSetMutation();
  const updateOrderSet = useUpdateOrderSetMutation();
  const { orderSet, orders, isFetching } = useCurrentOrderSet();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (submitOrderSet.isSuccess) {
      timeout = setTimeout(() => {
        const redirectTo = roleIs(PLANNING_TOOL_SUPPLIER)
          ? "/"
          : "/orders/history/order";
        navigate(redirectTo, { replace: true });
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [submitOrderSet.isSuccess, navigate, roleIs]);

  if (!orderSet) return <Skeleton tw="h-60" />;

  const {
    totalEstimatedCost,
    totalEstimatedFreight,
    totalEstimatedTaxes,
    totalBeaconCost,
    totalQuantity,
    type: orderSetType,
    status,
  } = orderSet;

  const billableTotalEstimatedCost = _.sumBy(
    orders,
    (o) => +o.billableTotalEstimatedCost
  );

  const billableTotalEstimatedShippingCost = _.sumBy(
    orders,
    (o) => +o.billableTotalEstimatedShippingCost
  );

  const billableTotalEstimatedTax = _.sumBy(
    orders,
    (o) => +o.billableTotalEstimatedTax
  );

  const billableTotalBeaconCost = _.sumBy(
    orders,
    (o) => +o.billableTotalBeaconCost
  );

  const billableExtendedCost =
    billableTotalEstimatedCost +
    billableTotalEstimatedShippingCost +
    billableTotalEstimatedTax +
    billableTotalBeaconCost;

  const handleSubmitOrderSet = () => {
    setIsUpdateLoading(true);
    submitOrderSet
      .mutateAsync()
      .catch(setMutateError)
      .finally(() => setIsUpdateLoading(false));
  };

  const toggleIsWorkComplete = () => {
    setIsUpdateLoading(true);
    return updateOrderSet
      .mutateAsync({
        isWorkComplete: !orderSet?.isWorkComplete,
      })
      .catch(setMutateError)
      .finally(() => setIsUpdateLoading(false));
  };

  const rows = [
    [
      "Total Item Cost",
      <DisplayBillable
        billable={billableTotalEstimatedCost}
        estimate={totalEstimatedCost}
        orderType={orderSetType}
      />,
    ],
    [
      "Freight",
      <DisplayBillable
        billable={billableTotalEstimatedShippingCost}
        estimate={totalEstimatedFreight}
        orderType={orderSetType}
      />,
    ],
    [
      "Taxes",
      <DisplayBillable
        billable={billableTotalEstimatedTax}
        estimate={totalEstimatedTaxes}
        orderType={orderSetType}
      />,
    ],
    [
      "Beacon Cost",
      <DisplayBillable
        billable={billableTotalBeaconCost}
        estimate={totalBeaconCost}
        orderType={orderSetType}
      />,
    ],
  ];
  return (
    <>
      <Card tw="relative">
        <div tw="p-6 space-y-6">
          <div tw="grid grid-cols-2 gap-3">
            <Button
              component={Link}
              to="#"
              tw="whitespace-nowrap"
              variant="contained"
              color="secondary"
              startIcon={<Edit />}
            >
              Edit Order
            </Button>
            <OrderSetWrapupButton />
          </div>
          <div
            css={[
              tw`grid max-w-full grid-cols-[auto_1fr] gap-1 overflow-hidden transition-opacity text-neutral-500`,
              isFetching && tw`opacity-50`,
            ]}
          >
            <div>Total Items</div>
            <div tw="text-right">{totalQuantity}</div>

            {rows.map(([label, value], i) => (
              <React.Fragment key={i}>
                <Typography tw="text-neutral-500 text-base" noWrap>
                  {label}
                </Typography>
                <div tw="text-right text-neutral-700 font-medium">
                  {typeof value !== "object" ? formatMoneyString(value) : value}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div
            css={[
              tw`flex items-baseline justify-between text-lg`,
              isFetching && tw`opacity-50`,
            ]}
          >
            <div tw="text-neutral-500">Est. Extended Cost</div>
            <div tw="text-right text-neutral-700 font-medium">
              {formatMoneyString(
                orderSetType === "from-inventory"
                  ? billableExtendedCost
                  : orderSet.totalExtendedCost
              )}
            </div>
          </div>
          {orderSetType === "pre-order" ? (
            <SetIsWorkInProgressButton
              toggleWorkInProgress={toggleIsWorkComplete}
              isUpdateLoading={isUpdateLoading}
            />
          ) : (
            <>
              {["inactive", "in-progress"].includes(status) && (
                <StyledButton
                  fullWidth
                  cta
                  disabled={isFetching}
                  loading={isUpdateLoading}
                  onClick={handleSubmitOrderSet}
                >
                  Submit Order
                </StyledButton>
              )}
              {status === "submitted" && (
                <div tw="text-lg text-neutral-700">
                  {orderSetType === "from-inventory"
                    ? "Thank you for your submission. Orders are transmitted sequentially to the warehouse in the order they are received and thus may take a few minutes to process. Redirecting you in 5 seconds..."
                    : "Thank you for your submission. Redirecting you in 5 seconds..."}
                </div>
              )}
            </>
          )}
        </div>
        {isFetching && <LinearProgress tw="absolute inset-0 top-auto" />}
      </Card>
      {orderSetType !== "pre-order" && (
        <div tw="mt-4 flex justify-end">
          <Button
            variant="danger"
            onClick={() =>
              window.confirm(
                "Are you sure you want to delete this order-set?"
              ) && deleteOrderSet.mutate()
            }
            startIcon={<FaIcon icon="trash" variant="solid" tw="text-sm!" />}
          >
            Delete Order Set
          </Button>
        </div>
      )}
    </>
  );
};

export default OrderSetSummaryActionPanel;
