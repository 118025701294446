import apiClient from "./apiClient";

export { query } from "./clientHelpers";
export { jsonApiPaginate } from "./helperFunctions";
export { default as useApiResource } from "./useApiResource";
export type { default as routeResourceTypes } from "./routeResourceTypes";
export { default as parseApiErrorMessage } from "./parseApiErrorMessage";

export * from "./types";

const client = apiClient();

client.registerTransformer({
  type: ["item", "item-without-variants"],
  deserialize: (data) => ({
    ...data,
    itemTypeName: data.type,
  }),
});

export default client;
