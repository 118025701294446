import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { NON_SUPPLIER_ROLES } from "src/constants/permissions";

import { Territory } from "@models/Territory";
import client from "@services/api";
import useRoleIs from "@utils/useRoleIs";

export const territoriesKeyFactory = createQueryKeys("territories", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<Territory[]>("territories", { params })
        .then((res) => res.data),
  }),
});

export const useTerritories = (filter: Record<string, any>) => {
  const roleIs = useRoleIs();
  return useQuery({
    ...territoriesKeyFactory.list({ filter }),
    staleTime: Infinity,
    enabled: roleIs(NON_SUPPLIER_ROLES),
  });
};
