import { format } from "date-fns";
import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import {
  RStatus,
  ReportSource,
  ReportStatus,
  TGlobalReports,
  TReportState,
} from "./types";

let initialState: TGlobalReports = {
  reportQueue: [],
  requestReport: null,
  filenames: [],
  reportTrayOpen: true,
};

// builds a unique url for the request
const buildUrl = (resource: string, params: Record<string, any>) =>
  resource + "?" + query({ ...params, isReport: true });

const date = format(new Date(), "yyyy-MM-dd");
const createFilename = (reportName, filenames) => {
  let name: string;
  let i = 0;
  do {
    name = `${reportName}-${date}`;
    if (i > 0) name += `-v${i}`;
    i++;
  } while (filenames.includes(name));
  return name;
};

const reportSlice = createSlice({
  name: "globalReports",
  initialState,
  reducers: {
    requestReport(state, { payload: reportName }) {
      state.requestReport = reportName;
    },
    queueReport(state, action) {
      const {
        reportName: name,
        resource,
        params,
        source = ReportSource.CSV,
      } = action.payload;

      const shouldDefer = state.reportQueue.some((r) =>
        _.includes([ReportStatus.PENDING, ReportStatus.LOADING], r.status)
      );

      const url = buildUrl(resource, params);
      const filename = createFilename(name, state.filenames);
      const newReport: TReportState = {
        url,
        name,
        filename,
        id: _.uniqueId(),
        status: shouldDefer ? ReportStatus.PENDING : ReportStatus.LOADING,
        source,
      };
      state.reportQueue.unshift(newReport);
      state.filenames.push(filename);
      state.requestReport = null;
      state.reportTrayOpen = true;
    },
    setReportStatus(
      state,
      { payload: { id, status } }: { payload: { id: string; status: RStatus } }
    ) {
      const report = state.reportQueue.find((rep) => rep.id === id);

      if (report) {
        if (
          report.status === ReportStatus.LOADING &&
          status !== ReportStatus.LOADING
        ) {
          // Queue up the next report
          const nextReport = state.reportQueue.find(
            (rep) => rep.status === ReportStatus.PENDING
          );
          if (nextReport) {
            nextReport.status = ReportStatus.LOADING;
          }
        }
        report.status = status;
      }
    },
    clearReport(state, { payload: id }) {
      state.reportQueue = state.reportQueue.filter(
        (report) => report.id !== id
      );
    },
    clearAllReports(state) {
      state.reportQueue = [];
    },
    setReportTrayOpen(state, { payload: open }) {
      state.reportTrayOpen = open;
    },
  },
});

export const {
  requestReport,
  queueReport,
  setReportStatus,
  clearReport,
  clearAllReports,
  setReportTrayOpen,
} = reportSlice.actions;

export default reportSlice.reducer;
