/** @jsxImportSource @emotion/react */
import "twin.macro";

import { SUPPLIER_ROLES } from "src/constants/permissions";
import { formatDateString } from "src/utility/utilityFunctions";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { useAuthUser } from "@features/auth";
import { Filters, useFilterParams } from "@features/filters";
import {
  PurchaseOrderHistoryTable,
  usePaginatedPurchaseOrderVariantsQuery,
} from "@features/purchaseOrders";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

const PurchaseOrderHistory = () => {
  const [filters] = useFilterParams();
  const { channel } = useAuthUser();
  const roleIs = useRoleIs();
  const isSupplier = roleIs(SUPPLIER_ROLES);

  const boolStatusFilters = {
    "sent-to-flow": "poSentToFlow",
    "shipping-hold": "hasShipHold",
    "not-invoiced": "notInvoiced",
  };

  const boolFilter = boolStatusFilters[filters.purchaseOrderStatus];

  const statusFilters = boolFilter
    ? { [boolFilter]: true }
    : { status: filters.purchaseOrderStatus };

  const dateRange =
    filters.fromDate && filters.toDate
      ? `${formatDateString(filters.fromDate)} - ${formatDateString(
          filters.toDate
        )}`
      : null;
  const params = {
    filter: {
      isNotDirectCost: true,
      ...(filters.q && { [filters.searchBy ?? "poId"]: filters.q }),
      ...statusFilters,
      poType: filters.purchaseOrderType,
      poCompletedAtRange: dateRange,
      brandIds: filters.brands,
      programIds: filters.programs,
      supplierIds: filters.suppliers,
      purchaserIds: filters.purchasers,
      itemTypeIds: filters.itemTypes,
      channel: !isSupplier ? channel : null,
    },
    sort: filters.sort,
  };
  const tableProps = usePaginatedPurchaseOrderVariantsQuery(params);
  useReport("purchase-order-history", ["purchase-order-variants", params]);

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex items-center justify-between flex-wrap gap-3">
        <PageTitle title="Purchase Orders" />
        <div tw="flex gap-2">
          <RequestReportButton reportName="purchase-order-history" />
        </div>
      </div>
      <TableCardContainer>
        <Filters
          slots={[
            "dateRange",
            "purchaseOrderType",
            "purchaseOrderStatus",
            "brands",
            "programs",
            "itemTypes",
            ...(!isSupplier ? ["suppliers", "purchasers"] : []),
          ]}
          slotProps={{
            search: {
              searchBy: [
                "itemNumber",
                "purchaseOrderId",
                "invoiceNumber",
                "externalSapId",
                "supplierReference",
              ],
            },
            orderType: {
              orderTypeOptions: [
                "pre-order",
                "on-demand",
                "from-inventory",
                "fulfillment",
              ],
            },
            dateRange: {
              title: "PO Completed",
              chipTextPrefix: "Completed",
            },
          }}
          alwaysShow={["purchaseOrderStatus", "purchaseOrderType"]}
          defaultValues={{
            searchBy: "itemNumber",
            sort: "-po-submitted-at",
          }}
        />
        <PurchaseOrderHistoryTable {...tableProps} />
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default PurchaseOrderHistory;
