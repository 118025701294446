import { useSelector } from "react-redux";

import { NON_SUPPLIER_ROLES } from "src/constants/permissions";

import useRoleIs from "@utils/useRoleIs";

import { useSubStates } from "./subStateQueries";

export default function useCurrentRegionalTerritorySubStates() {
  const roleIs = useRoleIs();
  const territory = useSelector(
    (state: any) =>
      state.app.territory &&
      state.territories.territoryList.find((t) => t.id === state.app.territory)
  );

  const isAllRegions = !territory;
  const isRegional = !territory || territory.type === "Regional";

  const { data = [], isPending } = useSubStates(
    { filter: { territoryId: territory?.id } },
    { enabled: isRegional && !isAllRegions && roleIs(NON_SUPPLIER_ROLES) }
  );

  return {
    data,
    subStateIds: data.map((obj) => obj.id),
    isRegional,
    isAllRegions,
    isPending,
  };
}
