/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

import { utcDate } from "src/utility/utilityFunctions";

import { CreateProgramForm } from "@features/programs";

import { copyProgram } from "../../../redux/slices/planningTool/programSlice";
import AnaplanProgramSearch from "./AnaplanProgramSearch";

const ProgramCopyModal = ({ classes, open, handleClose, program }) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [showRegionalNational, setShowRegionalNational] = useState("");
  const [selectedAnaplanProgram, setSelectedAnaplanProgram] = useState(null);

  const handleCopy = (data) => {
    dispatch(
      copyProgram(program.id, {
        ...data,
        anaplanProgramId: selectedAnaplanProgram?.id,
      })
    );
    handleClose();
  };

  const BackButton = () => {
    return (
      <Button
        color="secondary"
        disabled={activeStep === 0}
        onClick={() => {
          if (activeStep === 1) {
            setShowRegionalNational("");
          }
          setActiveStep(activeStep - 1);
        }}
        sx={{ mr: 1 }}
      >
        Back
      </Button>
    );
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            {showRegionalNational === "" && activeStep === 0 && (
              <>
                <Typography variant="h6">Choose program type</Typography>
                <Stack
                  direction="row"
                  sx={{ pt: 3, justifyContent: "space-evenly" }}
                >
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      setShowRegionalNational("national");
                      setActiveStep(1);
                    }}
                  >
                    <Typography className={classes.titleText}>
                      {"National Program"}
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      setShowRegionalNational("regional");
                      setActiveStep(1);
                    }}
                  >
                    <Typography className={classes.titleText}>
                      {"Regional Program"}
                    </Typography>
                  </Button>
                </Stack>
              </>
            )}

            {showRegionalNational === "regional" && activeStep === 1 && (
              <>
                <AnaplanProgramSearch
                  program={program}
                  selectedAnaplanProgram={selectedAnaplanProgram}
                  setSelectedAnaplanProgram={setSelectedAnaplanProgram}
                />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BackButton />
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    variant={"contained"}
                    onClick={() => setActiveStep(2)}
                    disabled={selectedAnaplanProgram ? false : true}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}

            {((showRegionalNational === "national" && activeStep === 1) ||
              (showRegionalNational === "regional" && activeStep === 2)) && (
              <>
                <CreateProgramForm
                  defaultValues={{
                    brandAssignmentLevel: program.brandAssignmentLevel,
                    brands: program.brands,
                    channel: program.channel,
                    startDate: utcDate(
                      selectedAnaplanProgram?.startDate ?? program.startDate
                    ),
                    endDate: utcDate(
                      selectedAnaplanProgram?.endDate ?? program.endDate
                    ),
                    name: selectedAnaplanProgram?.name ?? "",
                  }}
                  disabledFields={[
                    "channel",
                    program.brandAssignmentLevel === "program" && "brands",
                    selectedAnaplanProgram && "name",
                  ]}
                  programTypeIds={[program.programTypeId]}
                  submitButtonText="Finish"
                  onSubmit={handleCopy}
                />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BackButton />
                  <Box sx={{ flex: "1 1 auto" }} />
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProgramCopyModal;
