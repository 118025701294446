import { Link, Navigate } from "react-router";

import { without } from "lodash";
import App from "src/App";
import {
  NON_SUPPLIER_ROLES,
  PURCHASERS_AND_SUPPLIERS,
  READ_ONLY,
  SUPPLIER_ROLES,
} from "src/constants/permissions";
import Approvals from "src/pages/Approvals";
import BudgetDetail from "src/pages/BudgetDetail";
import BudgetSummary from "src/pages/BudgetSummary";
import C2MItemHistory from "src/pages/C2MItemHistory";
import C2MOrderHistory from "src/pages/C2MOrderHistory";
import ComplianceContacts from "src/pages/ComplianceContacts";
import ComplianceItems from "src/pages/ComplianceItems";
import ComplianceOverrides from "src/pages/ComplianceOverrides";
import ComplianceRules from "src/pages/ComplianceRules";
import Dashboard from "src/pages/Dashboard";
import DeactivatedUser from "src/pages/DeactivatedUser";
import ErrorPage from "src/pages/ErrorPage";
import FourOhFour from "src/pages/FourOhFour";
import Help from "src/pages/Help";
import ItemCatalog from "src/pages/ItemCatalog";
import Login from "src/pages/Login";
import Logout from "src/pages/Logout";
import NewSupplier from "src/pages/NewSupplier";
import NewUser from "src/pages/NewUser";
import Order from "src/pages/Order";
import OrderHistory from "src/pages/OrderHistory";
import PendingCompliance from "src/pages/PendingCompliance";
import PlaceOrder from "src/pages/PlaceOrder";
import PlanningToolQuote from "src/pages/PlanningToolQuote";
import PreOrder from "src/pages/PreOrder";
import PreOrderProgram from "src/pages/PreOrderProgram";
import PreOrderProgramList from "src/pages/PreOrderProgramList";
import PurchaseOrderHistory from "src/pages/PurchaseOrderHistory";
import PurchaseOrderRollup from "src/pages/PurchaseOrderRollup";
import Quotes from "src/pages/Quotes";
import ReportsLanding from "src/pages/ReportsLanding";
import RfpHistory from "src/pages/RfpHistory";
import Rollup from "src/pages/Rollup";
import ShelfInventory from "src/pages/ShelfInventory";
import ShelfInventoryAdmin from "src/pages/ShelfInventoryAdmin";
import SingleOrder from "src/pages/SingleOrder";
import Styles from "src/pages/Styles";
import SuspenseRoute from "src/routes/SuspenseRoute";

import routeProfile from "./routeProfile";
import programPlanningRoute from "./routeProgramPlanning";
import reportsRoute from "./routeReports";
import routeSettings from "./routeSettings";
import { protectedRoute } from "./types";

const routesRoot: protectedRoute[] = [
  {
    path: "approveOrDenyItem",
    element: (
      <SuspenseRoute
        componentImport={() => import("src/pages/ApproveOrDenyItem")}
      />
    ),
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  { path: "dashboard", element: <Navigate to="/" replace /> },
  {
    path: "*",
    element: <App />,
    errorElement: <ErrorPage />,
    handle: { crumb: () => <Link to="/">Home</Link> },
    // Only logged-in users can access the children routes
    children: [
      { path: "", element: <Dashboard /> },
      { path: "new-supplier", element: <NewSupplier /> },
      { path: "new-user", element: <NewUser /> },
      { path: "deactivated-user", element: <DeactivatedUser /> },
      { path: "help", element: <Help /> },

      routeProfile,
      routeSettings,

      // temp page
      { path: "styles", element: <Styles /> },
      {
        path: "reports",
        element: <ReportsLanding />,
      },
      programPlanningRoute,
      {
        path: "programs",
        allowedRoles: NON_SUPPLIER_ROLES,
        children: [
          {
            path: "national",
            children: [
              {
                path: "",
                element: (
                  <PreOrderProgramList
                    orderWindowType={"national"}
                    visibility="active"
                  />
                ),
              },
              {
                path: "preview",
                element: (
                  <PreOrderProgramList
                    orderWindowType={"national"}
                    visibility={"visible"}
                  />
                ),
              },
            ],
          },
          {
            path: "secondary",
            children: [
              {
                path: "",
                element: (
                  <PreOrderProgramList
                    orderWindowType={"secondary"}
                    visibility="active"
                  />
                ),
              },
              {
                path: "preview",
                element: (
                  <PreOrderProgramList
                    orderWindowType={"secondary"}
                    visibility={"visible"}
                  />
                ),
              },
            ],
          },
          { path: ":programId", element: <PreOrderProgram /> },
          { path: ":programId/:orderWindowId", element: <PreOrderProgram /> },
        ],
      },
      {
        path: "orders",
        defaultChildrenAllowedRoles: NON_SUPPLIER_ROLES,
        children: [
          {
            path: "items/on-demand",
            allowedRoles: [...NON_SUPPLIER_ROLES, "planning-tool-supplier"],
            element: <PlaceOrder orderType="on-demand" />,
          },
          {
            path: "items/inventory",
            element: <PlaceOrder orderType="from-inventory" />,
          },
          {
            path: "items/:any",
            element: <FourOhFour />,
          },

          {
            path: "open/:orderSetId",
            allowedRoles: [...NON_SUPPLIER_ROLES, "planning-tool-supplier"],
            element: <Order />,
          },
          { path: "history/single-order/:orderId", element: <SingleOrder /> },
          { path: "history/c2m-order-history", element: <C2MOrderHistory /> },
          { path: "history/:groupby", element: <OrderHistory /> },
          {
            path: "history",
            element: <Navigate to="/orders/history/item" replace />,
          },
          { path: ":orderWindowId/:programId", element: <PreOrder /> },
        ],
      },
      {
        path: "approvals",

        allowedRoles: [
          "field2",
          "purchaser",
          "select-purchaser",
          "super",
          READ_ONLY,
          "compliance",
        ],
        children: [
          { path: "", element: <Navigate to="/approvals/item" replace /> },
          { path: "order", element: <Approvals view={"order"} /> },
          { path: "item", element: <Approvals view={"item"} /> },
        ],
      },
      {
        path: "rollup",
        allowedRoles: [
          "field2",
          "purchaser",
          "select-purchaser",
          "super",
          READ_ONLY,
          "compliance",
        ],
        children: [
          { path: "", element: <Navigate to="/rollup/overview" replace /> },
          { path: "overview", element: <Rollup view={"overview"} /> },
          { path: "order", element: <Rollup view={"order"} /> },
          { path: "item", element: <Rollup view={"item"} /> },
        ],
      },
      {
        path: "purchasing",
        defaultChildrenAllowedRoles: PURCHASERS_AND_SUPPLIERS,
        children: [
          {
            path: "rfp/:id",
            element: (
              <SuspenseRoute componentImport={() => import("src/pages/RFP")} />
            ),
          },
          { path: "rfp-history", element: <RfpHistory /> },
          {
            path: "po-rollup",
            allowedRoles: [
              "purchaser",
              "select-purchaser",
              "super",
              "planning-tool-supplier",
            ],
            children: [
              {
                path: "on-demand",
                element: <PurchaseOrderRollup view={"on-demand"} />,
              },
              {
                path: "pre-order",
                element: <PurchaseOrderRollup view={"pre-order"} />,
              },
              {
                path: "*",
                element: <Navigate to="/purchasing/po-rollup/on-demand" />,
              },
            ],
          },
          {
            path: "purchase-orders",
            children: [
              {
                path: ":purchaseOrderId",
                element: (
                  <SuspenseRoute
                    componentImport={() => import("src/pages/PurchaseOrder")}
                  />
                ),
              },
              {
                path: "",
                element: <PurchaseOrderHistory />,
              },
            ],
          },
        ],
      },
      {
        path: "items",
        allowedRoles: NON_SUPPLIER_ROLES,
        children: [
          { path: "", element: <ItemCatalog /> },
          { path: "archive", element: <ItemCatalog archive /> },
          { path: "c2m", element: <C2MItemHistory /> },
        ],
      },
      {
        path: "compliance",
        allowedRoles: without(NON_SUPPLIER_ROLES, "field1"),
        children: [
          { path: "pending/:orderIds", element: <PendingCompliance /> },
          { path: "contacts", element: <ComplianceContacts /> },
          {
            path: "items",
            element: <ComplianceItems />,
            allowedRoles: NON_SUPPLIER_ROLES,
          },
          {
            path: "overrides",
            element: <ComplianceOverrides />,
            allowedRoles: ["compliance", "super"],
          },
          {
            path: "rules",
            element: <ComplianceRules />,
            allowedRoles: NON_SUPPLIER_ROLES,
          },
        ],
      },
      reportsRoute,
      {
        path: "shelf-inventory",
        allowedRoles: ["field2", "purchaser", "select-purchaser", "super"],
        children: [
          { path: "", element: <ShelfInventoryAdmin /> },
          { path: ":id", element: <ShelfInventory /> },
        ],
      },
      {
        path: "budgets",
        allowedRoles: NON_SUPPLIER_ROLES,
        children: [
          { path: "", element: <BudgetSummary /> },
          { path: ":id", element: <BudgetDetail /> },
        ],
      },

      {
        path: "quotes",
        allowedRoles: SUPPLIER_ROLES,
        children: [
          { path: "", element: <Quotes /> },
          { path: ":qid", element: <PlanningToolQuote /> },
        ],
      },
      {
        path: "*",
        element: <FourOhFour />,
      },
    ],
  },
];

export default routesRoot;
