/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { Button } from "@features/ui";

import PlanningToolNav from "../components/PlanningTool/PlanningToolNav";
import ProgramDetails from "../components/PlanningTool/Programs/ProgramDetails";
import ProgramItemDetails from "../components/PlanningTool/RFQ/ProgramItemDetails";
import QuotesTable from "../components/PlanningTool/RFQ/QuotesTable";
import RFQsTable from "../components/PlanningTool/RFQ/RFQsTable";
import RfqModal from "../components/PlanningTool/RFQ/RfqModal/RfqModal";
import { Contained } from "../components/StyledComponents";
import { useProgramInfo } from "../hooks/planningToolHooks";
import { fetchSingleProgram } from "../redux/slices/planningTool/programSlice";
import { fetchRFQs, openRFQModal } from "../redux/slices/planningTool/rfqSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  buttonSpacing: {
    "& > *:not(:last-child)": {
      marginRight: "1em",
    },
  },
}));

const PlanningProgramRFQs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { programId: loadedProgramId } = useProgramInfo();

  const { itemId, programId } = useParams(); // get seqNum from url path

  const { rfqItems } = useSelector((state) => state.rfq);
  const [quoteList, setQuoteList] = useState([]);
  const [rfqList, setRfqList] = useState([]);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
  }, [dispatch]);

  useEffect(() => {
    let rfqs = [...rfqItems];
    if (itemId) {
      rfqs = rfqs.filter((rfq) => rfq.itemId === itemId);
    }

    const sortedQuotes = rfqs.reduce(
      (acc, rfq) => [
        ...acc,
        ...[...rfq.quotes].sort((a, b) => b.round - a.round),
      ],
      []
    );
    setQuoteList(sortedQuotes);

    rfqs.reverse();
    setRfqList(rfqs);
  }, [rfqItems, itemId]);

  useEffect(() => {
    if (loadedProgramId !== programId) dispatch(fetchSingleProgram(programId));
    dispatch(fetchRFQs({ programIds: [programId] }));
  }, [loadedProgramId, programId, dispatch]);

  return (
    <div tw="pb-40">
      <Helmet>
        <title>Planning Tool | Program RFQs</title>
      </Helmet>

      <RfqModal />

      <PlanningToolNav
        title="Request for Quotes"
        view="rfq"
        programId={programId}
        itemId={itemId}
      />

      <Contained>
        {/* Program overview */}

        {itemId ? (
          /* Item Overview */
          <ProgramItemDetails programId={programId} itemId={itemId} />
        ) : (
          loadedProgramId && <ProgramDetails />
        )}

        <br />

        {/* Quote Section table */}

        <div tw="flex justify-between mb-4">
          <Typography variant="h5">
            Quotes for this {itemId ? "item" : "program"}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              dispatch(
                openRFQModal({
                  init: { programId: programId, itemId: itemId },
                })
              )
            }
          >
            CREATE NEW RFQ
          </Button>
        </div>
        <QuotesTable classes={classes} data={quoteList} programId={programId} />
        <Typography variant="h5" tw="mt-8 mb-4">
          Request for quotes
        </Typography>
        <RFQsTable data={rfqList} programId={programId} />
      </Contained>
    </div>
  );
};

PlanningProgramRFQs.propTypes = {};

export default PlanningProgramRFQs;
