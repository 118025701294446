import * as Sentry from "@sentry/react";
import { DefaultError, Mutation, Query } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";

import handleSessionExpired from "@features/auth/handleSessionExpired";

class UnexpectedApiErrorMessage extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnexpectedApiErrorMessage";
  }
}

export const reportUnexpectedApiErrorMessage = (error: AxiosError) => {
  Sentry.withScope((scope) => {
    scope.setLevel("error");
    scope.setContext("response", {
      data: error.response?.data,
      status: error.response?.status,
      headers: error.response?.headers,
    });
    Sentry.captureException(new UnexpectedApiErrorMessage(error.message));
  });
};

const getExceptionLevel = (error: DefaultError): Sentry.SeverityLevel => {
  if (isAxiosError(error) && ![403, 500].includes(error.status!)) {
    return "info";
  }
  return "error";
};

// A cloudflare 404 doesn't hit the render backend and so doesn't get the rndr-id header
const isCF404 = (error: DefaultError) => {
  if (error.response?.status === 404 && !error.response?.headers["rndr-id"]) {
    return true;
  }
  return false;
};

const handleCF404 = (error) => {
  Sentry.withScope((scope) => {
    scope.setFingerprint(["CF-404"]);
    scope.setContext("headers", {
      headers: error.response?.headers,
    });
    Sentry.captureException(error);
  });
};

export const captureAxiosException = (error: AxiosError) => {
  if (!error) return;
  if (error.response?.status === 401) {
    handleSessionExpired();
    return;
  }
  if (error.response?.status === 404 && !error.response?.headers?.["rndr-id"]) {
    handleCF404(error);
    return;
  }
  Sentry.captureException(error);
};

export const captureMutationException = (
  error: DefaultError,
  mutation: Mutation<unknown, unknown, unknown, unknown>
) => {
  if (isCF404(error)) {
    handleCF404(error);
    return;
  }
  Sentry.withScope((scope) => {
    scope.setLevel(getExceptionLevel(error));
    scope.setContext("mutation", {
      mutationId: mutation.mutationId,
      variables: mutation.state.variables,
    });
    if (mutation.options.mutationKey) {
      scope.setFingerprint(
        // Duplicate to prevent modification
        Array.from(mutation.options.mutationKey) as string[]
      );
    }
    Sentry.captureException(error);
  });
};

export const captureQueryException = (
  error: DefaultError,
  query: Query<unknown, unknown, unknown, readonly unknown[]>
) => {
  if (isCF404(error)) {
    handleCF404(error);
    return;
  }
  Sentry.withScope((scope) => {
    scope.setLevel(getExceptionLevel(error));
    scope.setContext("query", { queryHash: query.queryHash });
    const fingerprint = query.queryKey.map((key) => {
      if (typeof key === "number") return ":id";
      if (key === null) return ":null";
      if (typeof key === "object") return ":params";
      return String(key);
    });
    scope.setFingerprint(fingerprint);
    Sentry.captureException(error);
  });
};
